import { Autocomplete, Button, styled } from "@mui/material"

export const CustomAutocomplete = styled(Autocomplete)`
  width: 300px;

  .MuiFilledInput-root {
    padding: 0;
  }

  .MuiInputBase-root {
    height: 40px;
    padding-left: 8px;
  }
`

export const EmailBodyButton = styled(Button)`
  max-width: fit-content;
  margin-bottom: 16px;
`

export const ModifiedAutocomplete = styled(Autocomplete)`
  .MuiFilledInput-root {
    padding: 0;
  }

  .MuiInputBase-root {
    height: 28px;
    padding-left: 4px;

    .MuiAutocomplete-endAdornment {
      right: 4px;
    }
  }

  .MuiInputBase-input {
    font-size: 12px;
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiFilledInput-root {
    padding-right: 50px;
  }
`
