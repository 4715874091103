import { memo, useCallback, useMemo } from "react"
import { Box, Tooltip } from "@mui/material"
import { type ISectionItemProps } from "../../types"
import { AddItemContainer } from "./styled"
import { AddSubsectionItem } from "../AddSubsectionItem"
import { SubsectionItem } from "../SubsectionItem"
import { useTranslation } from "react-i18next"

export const SubsectionsList = memo((props: ISectionItemProps) => {
  const {
    claim,
    section,
    disabled,
    isComparisonPreview,
    subsectionsAdded,
    subsectionsWithError,
    isEditModeEnabled,
    hasSuperPermissionToEdit,
    permissionFields,
    onAddSubsectionClick,
  } = props

  const { t } = useTranslation()

  const hasPermissionToEditSection = useMemo(
    () =>
      !!claim?.permissions?.some(
        (p) =>
          p.action === "NONE" &&
          p.claimSectionType === section.sectionType &&
          (p.access === "Write" || p.access === "Delete"),
      ),
    [claim, section],
  )

  const hasPermissionToAdd = useCallback(
    (subsection: IClaimTemplateSection) =>
      !!hasSuperPermissionToEdit ||
      !!hasPermissionToEditSection ||
      !!subsection?.metadatas?.some(
        (m) => !!permissionFields?.includes(m.controlName),
      ),
    [hasSuperPermissionToEdit, hasPermissionToEditSection, permissionFields],
  )

  return (
    <>
      {section.subSections?.map((s) => {
        const isAdded =
          isComparisonPreview || !!subsectionsAdded?.includes(s.id)

        const hasSubsectionError = !!subsectionsWithError?.includes(s.id)

        const hasPermission = hasPermissionToAdd(s)

        const canAddNewSubsection = !disabled && hasPermission

        return (
          <Box id={s.id} key={s.id}>
            <Box
              display={!isAdded ? "block" : "none"}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Tooltip
                title={
                  !hasPermission
                    ? t("noPermissionToAddSubsectionTooltip")
                    : !isEditModeEnabled
                    ? t("editModeNotEnabledTooltip")
                    : null
                }
              >
                <AddItemContainer>
                  <AddSubsectionItem
                    key={s.id}
                    subsection={s}
                    onClick={onAddSubsectionClick}
                    disabled={!canAddNewSubsection}
                    error={hasSubsectionError}
                  />
                </AddItemContainer>
              </Tooltip>
            </Box>
            <Box display={isAdded ? "block" : "none"}>
              <SubsectionItem
                key={s.id}
                {...props}
                subsection={s}
                hasPermissionToEditSection={hasPermissionToEditSection}
              />
            </Box>
          </Box>
        )
      })}
    </>
  )
})
