import { Box, CircularProgress, ClickAwayListener } from "@mui/material"
import { TableContainerStyled, TableStyled } from "./styled"
import {
  type ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  VehiclesTableBody,
  VehiclesTableFooter,
  VehiclesTableHead,
} from "./components"
import { useQuery } from "@tanstack/react-query"
import { getVehiclesAPI } from "../../../../services"
import { useAppContext } from "../../../../contexts"

interface IProps {
  filters: Pick<IGetVehiclesParams, "search" | "allVehicles">
  isTableDisabled: boolean
  onDeleteVehicleClick?: (vehicle: IVehicle) => void
}

export const VehiclesListTable = (props: IProps) => {
  const { filters: filtersProp, isTableDisabled, onDeleteVehicleClick } = props
  const {
    state: { groupId },
  } = useAppContext()

  const [expandedRowId, setExpandedRowId] = useState<string | null>(null)

  const initialFilters = {
    search: undefined as string | undefined,
    sortField: "CreatedAt" as TGetVehiclesSort,
    isDescending: true,
    page: 1,
    pageSize: 20,
    groupId,
    ...filtersProp,
  }

  const [filters, setFilters] = useState(initialFilters)

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ["vehicles"],
    queryFn: () => getVehiclesAPI(filters),
  })

  useEffect(() => {
    setFilters({
      ...filters,
      page: 1,
      ...filtersProp,
    })
  }, [filtersProp])

  useEffect(() => {
    void refetch()
  }, [filters])

  const handleSort = useCallback(
    (_: React.MouseEvent<unknown>, property: keyof IVehicle) =>
      setFilters((prev) => ({
        ...prev,
        page: 1,
        sortField: property as TGetVehiclesSort,
        isDescending:
          prev.sortField === (property as TGetVehiclesSort)
            ? !prev.isDescending
            : false,
      })),
    [],
  )

  const onPageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
      setFilters((prev) => ({ ...prev, page: page + 1 })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) =>
      setFilters((prev) => ({
        ...prev,
        page: 1,
        pageSize: parseInt(e.target.value, 10),
      })),
    [],
  )

  const handleRowToggle = useCallback((id: string) => {
    setExpandedRowId((prev) => (prev === id ? null : id))
  }, [])

  const collapseRow = useCallback(() => {
    if (expandedRowId) {
      setExpandedRowId(null)
    }
  }, [expandedRowId])

  if (isLoading && !data) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={collapseRow}>
      <TableContainerStyled>
        <TableStyled className={isTableDisabled ? "disabled-effect" : ""}>
          <VehiclesTableHead
            order={filters.isDescending ? "desc" : "asc"}
            orderBy={filters.sortField}
            onRequestSort={handleSort}
            onHeaderClick={collapseRow}
          />
          <VehiclesTableBody
            rows={data?.vehicles ?? []}
            isRefetching={isRefetching || (isLoading && !!data)}
            onDeleteVehicleClick={onDeleteVehicleClick}
            expandedRowId={expandedRowId}
            onRowToggle={handleRowToggle}
            collapseRow={collapseRow}
          />
          <VehiclesTableFooter
            total={data?.totalCount ?? 0}
            page={filters.page - 1}
            pageSize={filters.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onFooterClick={collapseRow}
          />
        </TableStyled>
      </TableContainerStyled>
    </ClickAwayListener>
  )
}
