import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useToast } from "../../../../contexts"
import { sendTestEmailAPI } from "../../../../services"
import { LoadingButton } from "@mui/lab"
import { EMAIL_REGEX_VALIDATION } from "../../../../utils"
import { useEffect } from "react"

interface IProps {
  open: boolean
  emailType: string
  footerName: string
  fromEmail: string
  onClose: () => void
}

export const TestEmailDialog = (props: IProps) => {
  const { open, emailType, footerName, fromEmail, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
  })

  const { mutate: sendTestEmail, isPending: sendingTestEmail } = useMutation({
    mutationFn: (data: ITestEmailParams) => sendTestEmailAPI(data),
    onSuccess: () => {
      toast.show(t("testEmailSentSuccessfully"), "success")
      onClose()
    },
    onError: () => {
      toast.show(t("errorSendingTestEmail"), "error")
    },
  })

  const onFormSubmit = (data: { email: string }) => {
    sendTestEmail({
      emailType,
      to: data.email,
      from: fromEmail,
      footer: footerName,
    })
  }

  useEffect(() => {
    if (open) {
      reset({
        email: "",
      })
    }
  }, [open, reset])

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("sendTestEmail")}</DialogTitle>
      <DialogContent onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: t("required"),
            pattern: {
              value: EMAIL_REGEX_VALIDATION,
              message: t("emailNotValid"),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("email")}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onFormSubmit)}
          variant="contained"
          disabled={sendingTestEmail}
        >
          {t("send")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
