import { Box, styled, Card, CardContent } from "@mui/material"
import { colors } from "../../../../../../utils"

export const Wrapper = styled(Box)`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`

export const CardContainer = styled(Box)`
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
`

interface IStylingProps {
  key: string
  id: string
  selected: boolean | undefined
  onClick: (e: any) => any
}

export const CustomizedCard = styled(Card)<IStylingProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 7.5px;
  border: 1.5px
    ${(props: IStylingProps) => (props.selected ? "solid" : "dashed")}
    ${colors.primary};
  background-color: ${(props: IStylingProps) =>
    props.selected ? colors.blue6 : "white"};
  box-shadow: none;
`

export const CustomizedCardContent = styled(CardContent)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  pr: 0;
`

export const controlLabelStyle = {
  width: "100%",
  textAlign: "left",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  pt: 1,
}

export const LoadingBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledBox = styled(Box)`
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${colors.gray7};
  border-radius: 8px;
`
