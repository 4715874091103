import {
  Box,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Controller, type UseFormReturn } from "react-hook-form"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import {
  AutocompleteController,
  DateController,
} from "../../../../../../components"
import { getManufacturersAPI } from "../../../../../../services"
import { VEHICLE_TYPE_OPTIONS } from "../../../../../../utils"

interface IProps {
  methods: UseFormReturn<any, any, undefined>
  isLoading: boolean
}

export const VehicleDataTab = (props: IProps) => {
  const { isLoading, methods } = props
  const { t } = useTranslation()

  const { control, watch } = methods

  const { data: manufacturers, isLoading: areManufacturersLoading } = useQuery({
    queryKey: ["manufacturers"],
    queryFn: () => getManufacturersAPI(),
    refetchOnMount: true,
  })

  const vehicleTypeValue = watch("vehicleInformation.vehicleTypeSelection")

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap="16px"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" gap="16px" width="50%">
        <Box display="flex" flexDirection="column" gap="4px">
          <Controller
            name="vehicleInformation.vehicleTypeSelection"
            control={control}
            rules={{
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="8px"
              >
                <Box display="flex" flexWrap="wrap" gap="30px">
                  {VEHICLE_TYPE_OPTIONS?.map((type, index) => (
                    <Box key={index} display="flex" gap="4px">
                      <Checkbox
                        checked={value === type.value}
                        onChange={() => {
                          onChange(value === type.value ? null : type.value)
                          methods.setValue(
                            "vehicleInformation.vehicleType",
                            null,
                          )
                        }}
                        disabled={isLoading}
                      />
                      <Typography>{t(type.label)}</Typography>
                    </Box>
                  ))}
                </Box>
                <FormHelperText sx={{ marginLeft: "14px" }} error={!!error}>
                  {error?.message}
                </FormHelperText>
              </Box>
            )}
          />
          {vehicleTypeValue === "Andere" && (
            <Controller
              name="vehicleInformation.vehicleType"
              control={control}
              rules={{
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  label={t("vehicleType")}
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          )}
        </Box>
        <Box display="flex" gap="8px">
          <Controller
            name="vehicleInformation.officialRegistrationNumber"
            control={control}
            rules={{
              required: t("required"),
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                onChange={(event) =>
                  field.onChange(event.target.value?.toUpperCase())
                }
                label={`${t("officialRegistrationNumber")}*`}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="vehicleInformation.chassisNumber"
            control={control}
            rules={{
              required: t("required"),
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                label={`${t("chasisNumber")}*`}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box display="flex" gap="8px">
          <AutocompleteController
            formControl={control}
            rules={{
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            controlName="vehicleInformation.manufacturer"
            label={t("manufacturer")}
            options={manufacturers}
            areOptionsLoading={areManufacturersLoading}
          />
          <Controller
            name="vehicleInformation.manufacturerType"
            control={control}
            rules={{
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                label={t("manufacturerType")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box display="flex" gap="8px">
          <DateController
            formMethods={methods}
            controlName="vehicleInformation.initialRegistration"
            label={t("initialRegistration")}
            isDisabled={isLoading}
            maxDate={dayjs()}
          />
        </Box>
      </Box>
    </Box>
  )
}
