import { TableCell, TableBody, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EmptyTableRow, NoDataTableRow } from "./styled"
import React, { useEffect } from "react"
import { VehicleTableRow } from "./components"

interface IProps {
  rows?: IVehicle[]
  isRefetching?: boolean
  onDeleteVehicleClick?: (vehicle: IVehicle) => void
  expandedRowId: string | null
  onRowToggle: (id: string) => void
  collapseRow: () => void
}

export const VehiclesTableBody = (props: IProps) => {
  const {
    rows,
    isRefetching,
    onDeleteVehicleClick,
    onRowToggle,
    collapseRow,
    expandedRowId,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (expandedRowId) {
      setTimeout(() => {
        const targetElement = document.getElementById(`ste-${expandedRowId}`)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
        } else {
          const targetElement = document.getElementById("first-empty-row")
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth", block: "end" })
          }
        }
      }, 700)
    }
  }, [expandedRowId])

  return (
    <TableBody>
      <EmptyTableRow>
        <TableCell colSpan={7} height="16px" />
      </EmptyTableRow>
      {!rows?.length && (
        <NoDataTableRow>
          <TableCell align="center" colSpan={7}>
            <Typography>{t("noData")}</Typography>
          </TableCell>
        </NoDataTableRow>
      )}
      {!!rows?.length &&
        rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <VehicleTableRow
              isLoading={!!isRefetching}
              row={row}
              isDisabled={expandedRowId !== null && expandedRowId !== row.id}
              isExpanded={expandedRowId === row.id}
              onRowToggle={onRowToggle}
              collapseRow={collapseRow}
              onDeleteClick={onDeleteVehicleClick}
            />
            <EmptyTableRow id={`ste-${index > 0 ? rows?.[index + 1]?.id : ""}`}>
              <TableCell colSpan={7} height="16px" />
            </EmptyTableRow>
          </React.Fragment>
        ))}
    </TableBody>
  )
}
