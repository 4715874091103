import { useCallback, useState } from "react"
import { debounce } from "lodash"
import { Box, ClickAwayListener } from "@mui/material"
import { colors } from "../../utils/colors"
import { AUTHORIZED_CONTENT_MAX_WIDTH } from "../../utils"

import {
  CreateVehicleSection,
  VehicleHeader,
  VehiclesListTable,
} from "./components"
import { AlertDialog } from "../../components"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteVehicleAPI } from "../../services"
import { useToast } from "../../contexts"

export const VehicleManagementPage = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<
    Pick<IGetVehiclesParams, "search" | "allVehicles">
  >({
    search: "",
    allVehicles: localStorage.getItem("showAllVehicles") === "true",
  })
  const [isCreateVehicleDialogVisible, setCreateVehicileDialogVisible] =
    useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | undefined>(
    undefined,
  )
  const [isDeleteVehicleDialogVisible, setDeleteVehicleDialogVisible] =
    useState(false)

  const queryClient = useQueryClient()

  const toast = useToast()

  const { mutate: deleteVehicle, isPending: isVehicleDeleting } = useMutation({
    mutationFn: (id: string) => deleteVehicleAPI(id),
    onSuccess: () => {
      toast.show(t("vehicleDeletedSuccessfullyMessage"), "success")

      void queryClient.refetchQueries({ queryKey: ["vehicles"] })
    },
    onSettled: () => {
      setDeleteVehicleDialogVisible(false)
      setSelectedVehicle(undefined)
    },
  })

  const debouncedSearch = useCallback(
    debounce((text) => {
      setFilters((prev) => ({ ...prev, search: text }))
    }, 350),
    [],
  )

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value)
    },
    [],
  )

  const onShowAllChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFilters((prev) => ({ ...prev, allVehicles: checked }))
      localStorage.setItem("showAllVehicles", JSON.stringify(checked))
    },
    [],
  )

  const onNewVehicleClick = useCallback(
    () => setCreateVehicileDialogVisible(true),
    [],
  )

  const onCloseCreateVehicleDialog = useCallback(() => {
    setCreateVehicileDialogVisible(false)
    setSelectedVehicle(undefined)
  }, [])

  const onDeleteVehicleClick = useCallback((vehicle: any) => {
    setDeleteVehicleDialogVisible(true)
    setSelectedVehicle(vehicle)
  }, [])

  const handleConfirmDelete = useCallback(() => {
    deleteVehicle(selectedVehicle?.id!)
  }, [selectedVehicle])

  const handleCancelDelete = useCallback(() => {
    setDeleteVehicleDialogVisible(false)
    setSelectedVehicle(undefined)
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <AlertDialog
          isVisible={isDeleteVehicleDialogVisible}
          message={t("deleteDialogConfiratiomMessage", {
            registrationNumber:
              selectedVehicle?.vehicleInformation?.officialRegistrationNumber,
          })}
          confirmLabel={t("delete")}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          loading={isVehicleDeleting}
        />

        <VehicleHeader
          filters={filters}
          onSearchChange={handleSearch}
          onNewVehicleClick={onNewVehicleClick}
          onShowAllChange={onShowAllChange}
          isHeaderDisabled={isCreateVehicleDialogVisible}
        />
        {isCreateVehicleDialogVisible && (
          <ClickAwayListener onClickAway={onCloseCreateVehicleDialog}>
            <Box>
              <CreateVehicleSection
                selectedVehicle={selectedVehicle}
                onClose={onCloseCreateVehicleDialog}
                defaultTab={0}
              />
            </Box>
          </ClickAwayListener>
        )}
        <Box flex={1} display="grid" paddingBottom="24px">
          <VehiclesListTable
            filters={filters}
            onDeleteVehicleClick={onDeleteVehicleClick}
            isTableDisabled={isCreateVehicleDialogVisible}
          />
        </Box>
      </Box>
    </Box>
  )
}
