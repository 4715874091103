import { forwardRef } from "react"
import { TextField, Typography } from "@mui/material"
import {
  getCountries,
  type DefaultInputComponentProps,
  getCountryCallingCode,
} from "react-phone-number-input"
import {
  CountrySelect,
  InternationalNumberIcon,
  PhoneInput,
  SelectItem,
} from "./styled"
import { DEFAULT_PHONE_COUNTRY } from "../../utils"
import englishCountryList from "react-phone-number-input/locale/en.json"
import germanCountryList from "react-phone-number-input/locale/de.json"
import { useTranslation } from "react-i18next"

const InputWrapper = forwardRef(
  ({ label, disabled, onChange, value, ...rest }: any, ref) => {
    return (
      <TextField
        {...rest}
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputRef={ref}
        disabled={disabled}
      />
    )
  },
)

const CountriesDropdown = forwardRef(
  ({ value, onChange, disabled, ...rest }: any, ref) => {
    const { i18n } = useTranslation()
    const listOfCountries = getCountries().sort()

    return (
      <CountrySelect
        {...rest}
        value={value}
        MenuProps={{disablePortal: true}}
        onChange={(e) => onChange(e.target.value)}
        inputRef={ref}
        disabled={disabled}
        displayEmpty
        renderValue={() =>
          !value ? (
            <InternationalNumberIcon />
          ) : (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w80/${value.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w160/${value.toLowerCase()}.png 2x`}
              alt=""
            />
          )
        }
      >
        {listOfCountries?.map((option) => (
          <SelectItem key={option} value={option}>
            <Typography variant="regular">
              {i18n.language === "en"
                ? englishCountryList[option]
                : germanCountryList[option]}
            </Typography>
            <Typography variant="regular">
              +{getCountryCallingCode(option)}
            </Typography>
          </SelectItem>
        ))}
      </CountrySelect>
    )
  },
)

interface IProps extends DefaultInputComponentProps {
  label?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
}

export const PhoneNumberInput = (props: IProps) => {
  const {
    label,
    disabled = false,
    value,
    onChange,
    onBlur,
    error,
    helperText,
  } = props

  return (
    <PhoneInput
      international
      defaultCountry={DEFAULT_PHONE_COUNTRY}
      value={value}
      disabled={disabled}
      onChange={(e) => onChange(e ?? null)}
      onBlur={onBlur}
      countrySelectProps={{ error }}
      numberInputProps={{
        ...(!!label && { label }),
        error,
        helperText,
      }}
      inputComponent={InputWrapper}
      countrySelectComponent={CountriesDropdown}
    />
  )
}
