import {
  TableCell,
  TableSortLabel,
  useMediaQuery,
  Grid,
  TableHead,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TableRowStyled } from "./styled"

interface IHeadCell {
  id: keyof IVehicle | IVehicleInformation | IInsuranceInformation | any
  label?: string
  width: string
  subFields?: Array<{
    id: keyof IVehicle | IVehicleInformation | IInsuranceInformation | any
    label: string
  }>
  align?: any
}

interface IProps {
  order?: TOrder
  orderBy?: any
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof IVehicle,
  ) => void
  onHeaderClick?: () => void
}

export const VehiclesTableHead = (props: IProps) => {
  const { order, orderBy, onRequestSort, onHeaderClick } = props
  const { t } = useTranslation()
  const isGreaterThan1420 = useMediaQuery("(min-width: 1420px)")

  const headCells: readonly IHeadCell[] = [
    {
      id: "NumberPlate",
      width: "16%",
      subFields: [
        {
          id: "NumberPlate",
          label: t("officialRegistrationNumber"),
        },
        { id: "VIN", label: t("chasisNumber") },
      ],
    },
    {
      id: "Manufacturer",
      width: "16%",
      subFields: [
        { id: "Manufacturer", label: t("manufacturer") },
        {
          id: "ManufacturerType",
          label: t("manufacturerType"),
        },
      ],
    },
    {
      id: "VehicleType",
      width: "16%",
      subFields: [
        { id: "VehicleType", label: t("vehicleType") },
        {
          id: "RegistrationDate",
          label: t("initialRegistration"),
        },
      ],
    },
    {
      id: "InsuranceCompany",
      width: "16%",
      subFields: [
        { id: "InsuranceCompany", label: t("insurer") },
        { id: "Group", label: t("group") },
      ],
    },
    {
      id: "CreatedAt",
      width: "16%",
      subFields: [
        { id: "CreatedAt", label: t("createdDate") },
        { id: "CreatedBy", label: t("createdBy") },
      ],
    },
    {
      id: "LastUpdatedAt",
      width: "16%",
      subFields: [
        { id: "LastUpdatedAt", label: t("editedDate") },
        { id: "LastUpdatedBy", label: t("editedBy") },
      ],
    },
  ]

  return (
    <TableHead onClick={onHeaderClick}>
      <TableRowStyled>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="none"
            width={headCell.width}
            className={isGreaterThan1420 ? "word-break-all" : ""}
            style={{
              minWidth: isGreaterThan1420 ? headCell.width : "200px",
            }}
          >
            <Grid container direction="column">
              {!headCell?.subFields?.length ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) => onRequestSort?.(event, headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                <Grid container direction="column">
                  {headCell.subFields.map((subField: any) => (
                    <Grid item key={subField.id}>
                      <TableSortLabel
                        active={orderBy === subField.id}
                        direction={orderBy === subField.id ? order : "asc"}
                        onClick={(event) => onRequestSort?.(event, subField.id)}
                      >
                        {subField.label}
                      </TableSortLabel>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </TableCell>
        ))}
        <TableCell width="4%" />
      </TableRowStyled>
    </TableHead>
  )
}
