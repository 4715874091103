import { TableFooter, TableRow, TablePagination } from "@mui/material"
import { type ChangeEventHandler } from "react"
import { useTranslation } from "react-i18next"

interface IProps {
  total: number
  page: number
  pageSize: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onPageSizeChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFooterClick?: () => void
}

export const VehiclesTableFooter = (props: IProps) => {
  const {
    total,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFooterClick,
  } = props
  const { t } = useTranslation()

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={total ?? 0}
          page={page}
          rowsPerPage={pageSize}
          onPageChange={onPageChange}
          onRowsPerPageChange={onPageSizeChange}
          labelRowsPerPage={t("rowsPerPage")}
          onClick={onFooterClick}
        />
      </TableRow>
    </TableFooter>
  )
}
