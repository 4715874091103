import { IconButton, TableRow, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IRowProps {
  open: boolean
  disabled?: boolean
  expert?: boolean
}

interface ITabIconProps {
  active: boolean
}

export const CustomRow = styled(TableRow)<IRowProps>(
  ({ open, disabled, expert }) => ({
    borderRadius: "10px",
    transition: "all 0.2s ease-in-out",

    "& .MuiTableCell-root": {
      padding: "0px !important",

      transition: "all 0.2s ease-in-out",
      borderTop: `1px solid ${open ? colors.primary : colors.gray10}`,
      borderBottom: `1px solid ${colors.gray10} !important`,
      "&:nth-of-type(n+5)": {
        borderLeft: expert ? "0px" : `1px solid ${colors.gray10}`,
      },
      "&:nth-of-type(n+6)": {
        borderLeft: expert ? `1px solid ${colors.gray10}` : "0px",
      },
      "&:first-of-type": {
        borderLeft: `1px solid ${open ? colors.primary : colors.gray10}`,
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: open ? "0px" : "10px",
        paddingLeft: "6px !important",
      },
      "&:last-of-type": {
        borderRight: `1px solid ${open ? colors.primary : colors.gray10}`,
        borderLeft: "0px",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: open ? "0px" : "10px",
      },

      "& .MuiTypography-root": {
        lineHeight: "24px",
      },
    },

    "&:hover": {
      "&.MuiTableRow-root": {
        transform: open || disabled ? "none" : "scale(1.01)",
      },
      "& .MuiTableCell-root": {
        cursor: disabled ? "auto" : "pointer",
        borderTop: `1px solid ${
          open ? colors.primary : disabled ? colors.gray10 : colors.primary
        }`,
        borderBottom: `1px solid ${
          open || disabled ? colors.gray10 : colors.primary
        } !important`,

        "&:first-of-type": {
          borderLeft: `1px solid ${
            open ? colors.primary : disabled ? colors.gray10 : colors.primary
          }`,
        },
        "&:last-of-type": {
          borderRight: `1px solid ${
            open ? colors.primary : disabled ? colors.gray10 : colors.primary
          }`,
        },
      },
    },
  }),
)

export const CollapsibleRow = styled(TableRow)<IRowProps>(({ open }) => ({
  "& > .MuiTableCell-root": {
    borderBottom: open
      ? `1px solid ${colors.primary} !important`
      : "none !important",
    borderRight: `1px solid ${colors.primary}`,
    borderLeft: `1px solid ${colors.primary}`,
    borderTop: "none",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",

    padding: "0px !important",

    "& .MuiCollapse-root": {
      margin: "16px",
    },
  },
}))

export const TabIconButton = styled(IconButton)<ITabIconProps>(
  ({ active }) => ({
    border: `1px solid ${active ? colors.primary : colors.gray2}`,
    width: "37px",
    height: "37px",
  }),
)
