import { Box, Typography } from "@mui/material"
import { type IFieldItemControllerProps } from "../../../../types"
import { FileItem, FileUpload } from "../../../../../../components"
import { useTranslation } from "react-i18next"
import { memo, useCallback, useMemo, useRef } from "react"
import { colors } from "../../../../../../utils"

export const FileUploadController = memo((props: IFieldItemControllerProps) => {
  const {
    field,
    subsection,
    section,
    isDisabled,
    isUploading,
    claimDocuments,
    onFilePreviewClick,
    onUploadFiles,
    onDeleteFile,
  } = props
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const matchingDocument = useMemo(
    () =>
      claimDocuments?.find((doc) => field?.documentType === doc.documentType),
    [claimDocuments, field],
  )

  const fieldFiles = useMemo(
    () => matchingDocument?.files ?? [],
    [matchingDocument],
  )

  const fieldIsRequired = !!field?.rules?.isRequired && !fieldFiles?.length

  const onUploadClick = useCallback(() => inputRef?.current?.click(), [])

  const onFilesDrop = useCallback(
    (nextFiles: File[], documentType: string) =>
      onUploadFiles?.([...nextFiles], documentType, subsection, section),
    [],
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box
        component="input"
        ref={inputRef}
        type="file"
        accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, .txt, text/plain, image/jpeg, image/jpg, image/png, image/heic, image/heif"
        multiple
        hidden
        onChange={(event) => {
          if (event.target.files?.length) {
            onFilesDrop(event.target.files as any, field.documentType)
          }

          event.target.value = ""
        }}
      />
      <FileUpload
        isUploading={isUploading}
        onUploadClick={onUploadClick}
        onFilesDrop={(files: File[]) => {
          onFilesDrop(files, field.documentType)
        }}
        disabled={isDisabled}
        required={fieldIsRequired}
      />
      {!!fieldFiles?.length && (
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="16px">
          {fieldFiles.map((file, index) => (
            <FileItem
              key={index}
              marginTop="16px"
              file={file}
              onPreviewClick={() => {
                onFilePreviewClick?.(file)
              }}
              onRemoveClick={() => {
                onDeleteFile?.(file.id, field.documentType, subsection, section)
              }}
              disabled={isDisabled}
            />
          ))}
        </Box>
      )}
      {fieldIsRequired && (
        <Typography color={colors.red} variant="small">
          {t("required")}
        </Typography>
      )}
    </Box>
  )
})
