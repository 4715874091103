import { useMemo } from "react"
import { Controller, type UseFormReturn } from "react-hook-form"
import {
  TextField,
  Box,
  Checkbox,
  Typography,
  FormHelperText,
  InputAdornment,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { getInsuranceCompaniesAPI } from "../../../../../../services"
import { useQuery } from "@tanstack/react-query"
import { AutocompleteController } from "../../../../../../components"
import { EURO_SIGN } from "../../../../../../utils"

interface IProps {
  methods: UseFormReturn<any, any, undefined>
}

export const VehicleCoverageTab = (props: IProps) => {
  const { methods } = props

  const { t } = useTranslation()

  const { control, watch } = methods

  const {
    data: liabilityInsuranceCompanies,
    isLoading: areLiabilityInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["liability-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "Liability" }),
    refetchOnMount: true,
  })

  const liabilityInsuranceCompanyOptions = useMemo(
    () =>
      liabilityInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [liabilityInsuranceCompanies],
  )

  const fullCoverage = watch(
    "fullPartialComprehensiveInsurance.fullyComprehensiveInsurance",
  )
  const partialCoverage = watch(
    "fullPartialComprehensiveInsurance.partialCover",
  )

  return (
    <Box
      alignItems="center"
      component="form"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box display="flex" flexDirection="column" gap="16px" width="50%">
        <Typography variant="smallMedium">{t("fullCoverage")}</Typography>

        <Box display="flex" gap="16px" alignItems="center">
          <Controller
            name="fullPartialComprehensiveInsurance.fullyComprehensiveInsurance"
            control={control}
            rules={{
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="8px"
              >
                <Box display="flex" flexWrap="wrap" gap="30px">
                  <Box display="flex" gap="4px">
                    <Checkbox
                      checked={value === "yes"}
                      onChange={() => onChange("yes")}
                    />
                    <Typography>{t("yes")}</Typography>
                  </Box>
                  <Box display="flex" gap="4px">
                    <Checkbox
                      checked={value === "no"}
                      onChange={() => {
                        onChange("no")

                        methods.setValue(
                          "fullPartialComprehensiveInsurance.deductible",
                          null,
                        )
                      }}
                    />
                    <Typography>{t("no")}</Typography>
                  </Box>
                </Box>
                <FormHelperText sx={{ marginLeft: "14px" }} error={!!error}>
                  {error?.message}
                </FormHelperText>
              </Box>
            )}
          />
        </Box>

        {fullCoverage === "yes" && (
          <Controller
            name="fullPartialComprehensiveInsurance.deductible"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("deductible")}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="large">{EURO_SIGN}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}

        <Typography variant="smallMedium">{t("partialCoverage")}</Typography>

        <Box display="flex" gap="16px" alignItems="center">
          <Controller
            name="fullPartialComprehensiveInsurance.partialCover"
            control={control}
            rules={{
              minLength: {
                message: t("minLengthErrorMessage", { value: 2 }),
                value: 2,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="8px"
              >
                <Box display="flex" flexWrap="wrap" gap="30px">
                  <Box display="flex" gap="4px">
                    <Checkbox
                      checked={value === "yes"}
                      onChange={() => onChange("yes")}
                    />
                    <Typography>{t("yes")}</Typography>
                  </Box>
                  <Box display="flex" gap="4px">
                    <Checkbox
                      checked={value === "no"}
                      onChange={() => {
                        onChange("no")

                        methods.setValue(
                          "fullPartialComprehensiveInsurance.selfParticipation",
                          null,
                        )
                      }}
                    />
                    <Typography>{t("no")}</Typography>
                  </Box>
                </Box>
                <FormHelperText sx={{ marginLeft: "14px" }} error={!!error}>
                  {error?.message}
                </FormHelperText>
              </Box>
            )}
          />
        </Box>

        {partialCoverage === "yes" && (
          <Controller
            name="fullPartialComprehensiveInsurance.selfParticipation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("deductible")}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="large">{EURO_SIGN}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}

        <Box display="flex" gap="8px">
          <AutocompleteController
            formControl={control}
            controlName="fullPartialComprehensiveInsurance.insuranceInformation.insurer"
            label={t("insurerInsurance")}
            options={liabilityInsuranceCompanyOptions}
            areOptionsLoading={areLiabilityInsuranceCompaniesLoading}
          />

          <Controller
            name="fullPartialComprehensiveInsurance.insuranceInformation.insuranceNo"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("insurerInsuranceNo")} fullWidth />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}
