import { Box, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { colors } from "../../../../utils"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { LoadingButton } from "@mui/lab"
import { useQuery } from "@tanstack/react-query"

interface IProps {
  claim: IClaimDetails
  disabled: boolean
  isClaimSaving: boolean
  onBackPress: () => void
  onSubmitClick: () => void
  onSaveAsDraftClick: () => void
}

export const FormFooter = (props: IProps) => {
  const {
    claim,
    disabled,
    isClaimSaving,
    onBackPress,
    onSaveAsDraftClick,
    onSubmitClick,
  } = props
  const { t } = useTranslation()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionToSubmit = useMemo(() => {
    return user?.permissions?.some(
      (p) => p.action === "SUBMIT_CLAIM" && p.access === "Delete",
    )
  }, [claim])

  const shouldSaveAsDraftBeVisible = useMemo(
    () => claim?.status === "InCreation",
    [claim],
  )

  return (
    <Box
      borderTop={`1px solid ${colors.gray13}`}
      display="flex"
      padding="16px"
      gap="16px"
    >
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        alignItems="center"
        flexWrap="wrap"
        gap="10px"
      >
        <Box marginLeft="-6px">
          <IconButton onClick={onBackPress} color="primary">
            <ArrowBackIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="8px">
        {!!shouldSaveAsDraftBeVisible && (
          <LoadingButton
            variant="outlined"
            onClick={onSaveAsDraftClick}
            loading={isClaimSaving}
            disabled={disabled}
          >
            {t("saveAsDraft")}
          </LoadingButton>
        )}
        {hasPermissionToSubmit && (
          <LoadingButton onClick={onSubmitClick} disabled={disabled}>
            {t("submit")}
          </LoadingButton>
        )}
      </Box>
    </Box>
  )
}
