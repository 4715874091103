import { useState, useCallback, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from "@mui/material"

import { useQuery } from "@tanstack/react-query"
import SearchIcon from "@mui/icons-material/Search"
import { getOrganizationsAPI } from "../../services"
import {
  filterOrganizationsBySearch,
  findAllSubgroupsSelected,
  recursiveFilterGroups,
} from "../../utils"
import { GroupLineItem } from "../../components"
import { useWindowDimensions } from "../../hooks"
import { LoadingButton } from "@mui/lab"

interface IProps {
  title?: string
  isVisible: boolean
  multiselect?: boolean
  prioOrganizations?: IOrganization[]
  groups: string[]
  isSubmitButtonLoading?: boolean
  hideGroups?: string[]
  selectAll?: boolean
  submitButtonLabel?: string
  onSubmit: (selectedGroups: string[], areAllGroupsSelected: boolean) => void
  onClose: () => void
}

export const GroupSelectDialog = (props: IProps) => {
  const {
    title,
    isVisible,
    multiselect = false,
    selectAll,
    groups,
    prioOrganizations,
    isSubmitButtonLoading,
    hideGroups,
    submitButtonLabel,
    onSubmit,
    onClose,
  } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const { height } = useWindowDimensions()

  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    groups?.length ? groups : [],
  )

  const { data } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
    enabled: !prioOrganizations,
  })

  const organizations = prioOrganizations ?? data

  const filteredOrganizations = useMemo(() => {
    let nextOrganizations = organizations ? [...organizations] : undefined

    if (hideGroups?.length) {
      nextOrganizations = organizations
        ?.map((o) => ({
          ...o,
          groups: recursiveFilterGroups(o.groups, hideGroups),
        }))
        ?.filter((o) => !!o.groups?.length)
    }

    if (nextOrganizations?.length) {
      if (search) {
        nextOrganizations = filterOrganizationsBySearch(
          nextOrganizations,
          search,
        )
      }

      nextOrganizations = nextOrganizations.sort((a, b) =>
        a.name.localeCompare(b.name),
      )
    }

    return nextOrganizations
  }, [organizations, search, hideGroups])

  const areAllGroupsSelected = useMemo(
    () =>
      selectedGroups?.length ===
      filteredOrganizations?.flatMap((org) => org.groups || [])?.length,
    [selectedGroups, filteredOrganizations],
  )

  useEffect(() => {
    if (selectAll) {
      setSelectedGroups(
        filteredOrganizations?.flatMap((o) => o.groups)?.map((g) => g.id) ?? [],
      )
    } else {
      setSelectedGroups(groups)
    }

    if (!isVisible) {
      setSearch("")
    }
  }, [groups, selectAll, isVisible])

  const isSelected = useCallback(
    (id: string) => selectedGroups.includes(id),
    [selectedGroups],
  )

  const handleGroupChange = useCallback(
    (group: IGroup, selectedParentId?: string) => {
      if (multiselect) {
        if (isSelected(group.id)) {
          setSelectedGroups(
            selectedGroups.filter((groupId) => groupId !== group.id),
          )
        } else {
          let nextSelectedGroups = [...selectedGroups]

          if (selectedParentId) {
            nextSelectedGroups = nextSelectedGroups?.filter(
              (g) => g !== selectedParentId,
            )
          } else {
            const selectedSubgroups = findAllSubgroupsSelected(
              group,
              selectedGroups,
            )

            if (selectedSubgroups?.length) {
              nextSelectedGroups = nextSelectedGroups?.filter(
                (g) => !selectedSubgroups.includes(g),
              )
            }
          }

          setSelectedGroups([...nextSelectedGroups, group.id])
        }
      } else {
        setSelectedGroups((prev) => (prev?.[0] === group.id ? [] : [group.id]))
      }
    },
    [selectedGroups, multiselect, isSelected],
  )

  const handleSelectAll = useCallback(
    () =>
      areAllGroupsSelected
        ? setSelectedGroups([])
        : setSelectedGroups(
            filteredOrganizations?.flatMap((o) => o.groups)?.map((g) => g.id) ??
              [],
          ),
    [filteredOrganizations, areAllGroupsSelected],
  )

  const handleSubmit = useCallback(() => {
    onSubmit(selectedGroups, areAllGroupsSelected)
  }, [selectedGroups, areAllGroupsSelected, onSubmit])

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearch(text)
    }, 350),
    [],
  )

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value)
    },
    [],
  )

  const handleClose = useCallback(
    (_e: any, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick") {
        return
      }
      onClose?.()
    },
    [],
  )

  return (
    <Dialog open={isVisible} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>{title ?? t("groupSelection")}</DialogTitle>
      <Box
        paddingX="16px"
        display="flex"
        alignItems="center"
        gap="16px"
        marginBottom="8px"
      >
        {multiselect && (
          <Box display="flex" alignItems="center">
            <Typography marginRight="8px" variant="regularBold">
              {t("select")}:
            </Typography>
            <Radio
              checked={areAllGroupsSelected}
              // onChange={handleSelectAll}
              onClick={handleSelectAll}
            />
            <Typography>{t("allGroups")}</Typography>
          </Box>
        )}
        <TextField
          sx={{ flex: 1 }}
          variant="outlined"
          placeholder={t("search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      <DialogContent>
        {!filteredOrganizations?.length && (
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
            height={height < 500 ? "auto" : "150px"}
          >
            <Typography textAlign="center">
              {search?.length
                ? t("noOrganizationsBySearch")
                : t("noOrganizations")}
            </Typography>
          </Box>
        )}
        {!!filteredOrganizations?.length &&
          filteredOrganizations.map((organization) => (
            <Box
              key={organization.id}
              gap="8px"
              display="flex"
              flexDirection="column"
            >
              <Typography textAlign="start" variant="regularBold">
                {organization.name}
              </Typography>
              {organization.groups
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <GroupLineItem
                    key={group.id}
                    group={group}
                    selectedGroupIds={selectedGroups}
                    onChangeGroup={handleGroupChange}
                    isTopParent
                    multiselect={multiselect}
                  />
                ))}
              <Box marginTop="2px" />
            </Box>
          ))}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={isSubmitButtonLoading}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          loading={isSubmitButtonLoading}
          disabled={isSubmitButtonLoading}
          onClick={handleSubmit}
          className="button-min-width"
        >
          {submitButtonLabel ?? t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
