import { useEffect, useMemo } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Controller, useForm } from "react-hook-form"
import { updateGroupUserRolesAPI, getOrganizationsAPI } from "../../services"
import { useToast } from "../../contexts"

interface UpdateUserRolesDialogProps {
  isOpen: boolean
  onClose: () => void
  groupId: string
  userId: string
  userRoles: {
    claimRoles: string[]
    groupRole: string
  }
}

export const UpdateUserRolesDialog = (props: UpdateUserRolesDialogProps) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    isOpen,
    onClose,
    groupId,
    userId,
    userRoles = { claimRoles: [], groupRole: "" },
  } = props

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const selectedOrganization = useMemo(() => {
    if (!organizations || !groupId) return null
    return organizations.find((org) =>
      org.groups.some((group) => group.id === groupId),
    )
  }, [organizations, groupId])

  const sortedGroupRoles = useMemo(() => {
    return [
      { value: "GroupOwner", label: t("groupOwner") },
      { value: "GroupMember", label: t("groupMember") },
    ].sort((a, b) => a.label.localeCompare(b.label))
  }, [t])

  const sortedClaimRoles = useMemo(() => {
    if (!selectedOrganization) return []
    return selectedOrganization.claimRoles
      .map((claimRole) => ({
        value: claimRole.claimRoleType,
        label: t(claimRole.claimRoleType.toLowerCase()),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [selectedOrganization, t])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    groupRole: string
    claimRoles: string[]
  }>({
    defaultValues: {
      groupRole: userRoles.groupRole,
      claimRoles: userRoles.claimRoles,
    },
  })

  useEffect(() => {
    if (isOpen && userRoles) {
      reset({
        groupRole: userRoles.groupRole,
        claimRoles: userRoles.claimRoles,
      })
    }
  }, [isOpen, userRoles, reset])

  const { mutate: updateUserRoles, isPending: isUpdating } = useMutation({
    mutationFn: (data: { groupRole: string; claimRoles: string[] }) =>
      updateGroupUserRolesAPI(groupId, userId, data.claimRoles, data.groupRole),
    onSuccess: () => {
      toast.show(t("userRolesUpdated"), "success")
      void queryClient.refetchQueries({ queryKey: ["groupUsers"] })
      onClose()
    },
    onError: () => {
      toast.show(t("errorUpdatingUserRoles"), "error")
    },
  })

  const onFormSubmit = (data: { groupRole: string; claimRoles: string[] }) => {
    updateUserRoles(data)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle>{t("updateUserRoles")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal" error={!!errors.groupRole}>
            <InputLabel>{t("groupRole")}</InputLabel>
            <Controller
              name="groupRole"
              control={control}
              rules={{ required: t("required") }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  label={t("groupRole")}
                >
                  {sortedGroupRoles.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.groupRole && (
              <FormHelperText>{errors.groupRole.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.claimRoles}>
            <InputLabel>{t("claimRoleType")}</InputLabel>
            <Controller
              name="claimRoles"
              control={control}
              rules={{ required: t("required") }}
              render={({ field: { value, onChange } }) => (
                <Select
                  multiple
                  value={value}
                  onChange={onChange}
                  label={t("claimRoleType")}
                  renderValue={(selected) =>
                    selected
                      .map((role) => {
                        const foundRole = sortedClaimRoles.find(
                          (r) => r.value === role,
                        )
                        return foundRole ? foundRole.label : role
                      })
                      .join(", ")
                  }
                >
                  {sortedClaimRoles.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.claimRoles && (
              <FormHelperText>{errors.claimRoles.message}</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isUpdating}
          >
            {isUpdating ? <CircularProgress size={24} /> : t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
