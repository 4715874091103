import {
  type DragEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { getClaimDocuments } from "../../../../services"
import { useQuery } from "@tanstack/react-query"
import { colors, determineFileType } from "../../../../utils"
import DocViewer, {
  DocViewerRenderers,
  type IDocument,
} from "@cyntler/react-doc-viewer"
import { TableContainerStyled, TableRowStyled } from "./styled"
import { UploadFileDialog } from "./components"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import dayjs from "dayjs"

interface IProps {
  claimId: string
}

export const DocumentsOverview = (props: IProps) => {
  const { claimId } = props
  const { t } = useTranslation()

  const {
    data: claimDocuments,
    isLoading: areClaimDocumentsLoading,
    isRefetching: areClaimDoucmentsRefetching,
    isError: getClaimDocumentsFailed,
  } = useQuery({
    queryKey: ["claim-documents", claimId],
    queryFn: () => getClaimDocuments(claimId),
    refetchOnMount: "always",
  })

  const inputRef = useRef<HTMLInputElement>(null)

  const [isDragging, setDragging] = useState(false)

  const [fileDownloading, setFileDownloading] = useState<string | undefined>()

  const [selectedNextFile, setSelectedNextFile] = useState<File | undefined>()

  const [selectedFileId, setSelectedFileId] = useState<string | undefined>()

  const selectedFile = useMemo(() => {
    if (selectedFileId) {
      return claimDocuments?.find((d) => d.id === selectedFileId)
    }

    return claimDocuments?.[0]
  }, [claimDocuments, selectedFileId])

  const handleFileUploaded = useCallback((newFileId: string) => {
    setSelectedFileId(newFileId)
  }, [])

  const documents: IDocument[] | undefined = useMemo(() => {
    if (!selectedFile) {
      return undefined
    }

    return [
      {
        fileName: selectedFile.name,
        fileType: determineFileType(selectedFile.name),
        uri: selectedFile.url,
        dateId: new Date().getTime(),
      },
    ]
  }, [selectedFile])

  const config = useMemo(
    () => ({
      header: {
        disableHeader: true,
      },
      pdfVerticalScrollByDefault: true,
    }),
    [],
  )

  const isDataLoading = areClaimDocumentsLoading || areClaimDoucmentsRefetching

  const handleDragEnter: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!isDataLoading) {
        setDragging(true)
      }
    },
    [isDataLoading],
  )

  const handleDragLeave: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!isDataLoading) {
        setDragging(false)
      }
    },
    [isDataLoading],
  )

  const handleDragOver: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!isDataLoading) {
        e.dataTransfer.dropEffect = "copy"
      }
    },
    [isDataLoading],
  )

  const onFilesDrop = useCallback((file: File) => setSelectedNextFile(file), [])

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!isDataLoading) {
        setDragging(false)
        const files = Array.from(e.dataTransfer.files)
        if (files?.length) {
          onFilesDrop(files[0])
        }
      }
    },
    [onFilesDrop, isDataLoading],
  )

  const onUploadClick = useCallback(() => inputRef?.current?.click(), [])

  const onDowloadClick = useCallback((file: IClaimFile) => {
    setFileDownloading(file.id)
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a")
        const objectURL = window.URL.createObjectURL(blob)
        link.href = objectURL
        link.download = file.name
        link.click()
      })
      .catch(() => {})
      .finally(() => {
        setFileDownloading(undefined)
      })
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={colors.white}
      onDragEnter={handleDragEnter}
      position="relative"
      justifyContent="center"
      alignItems="center"
    >
      <UploadFileDialog
        claimId={claimId}
        selectedNextFile={selectedNextFile}
        onClose={() => setSelectedNextFile(undefined)}
        onFileUploaded={handleFileUploaded}
      />
      <Box
        component="input"
        ref={inputRef}
        type="file"
        accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, .txt, text/plain, image/jpeg, image/jpg, image/png, image/heic, image/heif"
        // multiple
        hidden
        onChange={(event) => {
          if (event.target.files?.length) {
            onFilesDrop(event.target.files?.[0] as any)
          }

          event.target.value = ""
        }}
      />
      {isDragging && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          zIndex={99}
          bgcolor={colors.gray10 + "AA"}
          border={`1px dashed ${colors.primary}`}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="largeMedium">{t("dropFileHere")}</Typography>
        </Box>
      )}
      {isDataLoading && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          paddingBottom="24px"
        >
          <CircularProgress />
        </Box>
      )}
      {getClaimDocumentsFailed && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("failedToLoad")}</Typography>
        </Box>
      )}
      {!isDataLoading && !claimDocuments?.length && (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <Typography marginBottom="24px">{t("noDocumentsAlert")}</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop="24px"
          >
            <Button onClick={onUploadClick}>{t("uploadFile")}</Button>
            <Box
              marginLeft="8px"
              height="1px"
              width="8px"
              bgcolor={colors.gray3}
            />
            <Typography
              marginLeft="4px"
              color={colors.gray3}
              variant="smallMedium"
            >
              {t("or")}
            </Typography>
            <Box
              marginLeft="4px"
              height="1px"
              width="8px"
              bgcolor={colors.gray3}
            />
            <Typography
              marginLeft="8px"
              color={colors.gray3}
              variant="smallMedium"
            >
              {t("dragDropFileHere")}
            </Typography>
          </Box>
        </Box>
      )}
      {!isDataLoading && !!claimDocuments?.length && (
        <Box display="flex" flexDirection="column" gap="24px" width="100%">
          <Box display="flex" flexDirection="row" gap="16px" width="100%">
            <Box flex="1" width="50%">
              <TableContainerStyled>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("category")}</TableCell>
                      <TableCell>{t("sender")}</TableCell>
                      <TableCell>{t("name")}</TableCell>
                      <TableCell>{t("date")}</TableCell>
                      <TableCell>{t("type")}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimDocuments.map((file) => (
                      <TableRowStyled
                        key={file.id}
                        onClick={() => setSelectedFileId(file.id)}
                        selected={file.id === selectedFile?.id}
                      >
                        <TableCell>
                          <Typography variant="small" className="line-clamp-1">
                            {t(file.type as any)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="small" className="line-clamp-1">
                            {t(file?.sender as any)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="small" className="line-clamp-1">
                            {file.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {dayjs(file.createdAt).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell>{determineFileType(file.name)}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            disabled={file.id === fileDownloading}
                            onClick={() => onDowloadClick(file)}
                          >
                            <FileDownloadOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRowStyled>
                    ))}
                  </TableBody>
                </Table>
              </TableContainerStyled>
            </Box>

            <Box
              sx={{
                minWidth: "50%",
                width: "50%",
                backgroundColor: colors.white,
                padding: "16px",
                borderRadius: "8px",
                border: `1px solid ${colors.gray2}`,
                maxHeight: "600px",
              }}
            >
              {!!documents?.[0]?.uri && (
                <DocViewer
                  key={documents[0].uri}
                  documents={documents}
                  pluginRenderers={DocViewerRenderers}
                  config={config}
                  className={
                    documents[0].uri.endsWith(".docx")
                      ? "doc-viewer-disable-scroll"
                      : ""
                  }
                />
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderTop={`1px solid ${colors.gray5}`}
            paddingTop="8px"
          >
            <Button onClick={onUploadClick}>{t("uploadFile")}</Button>
            <Box
              marginLeft="8px"
              height="1px"
              width="8px"
              bgcolor={colors.gray3}
            />
            <Typography
              marginLeft="4px"
              color={colors.gray3}
              variant="smallMedium"
            >
              {t("or")}
            </Typography>
            <Box
              marginLeft="4px"
              height="1px"
              width="8px"
              bgcolor={colors.gray3}
            />
            <Typography
              marginLeft="8px"
              color={colors.gray3}
              variant="smallMedium"
            >
              {t("dragDropFileHere")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
