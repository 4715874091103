import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Switch,
} from "@mui/material"
import { Search, Add } from "@mui/icons-material"
import dayjs from "dayjs"
import { LoadingButton } from "@mui/lab"
import { colors, AUTHORIZED_CONTENT_MAX_WIDTH } from "../../utils"
import {
  createClaimAPI,
  getClaimsListingAPI,
  updateSearchFiltersAPI,
} from "../../services"
import {
  checkIfDate,
  getFiltersCount,
  transformFiltersForPopover,
} from "./utils"
import { SwitchControlLabel } from "./styled"
import { useAppContext } from "../../contexts"
import {
  ActiveFiltersBar,
  ClaimsTable,
  FilterPopup,
  type IFilter,
} from "./components"

export const ClaimsListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))

  const [searchKey, setSearchKey] = useState("")
  const client = useQueryClient()
  const { state: appState } = useAppContext()
  const [page, setPage] = useState(0)

  const initialFilters = {
    page: 1,
    pageSize: 100000,
    viewAllClaims: JSON.parse(
      localStorage.getItem("showAllClaims")! ?? "false",
    ),
    groupId: appState?.groupId!,
  }

  const [endpointParams, setEndpointParams] =
    useState<IGetClaimsParams>(initialFilters)
  const [tableFilters, setTableFilters] = useState<IClaimListingFilters>()

  const tableIsFiltered = useMemo(() => {
    return getFiltersCount(transformFiltersForPopover(tableFilters!)) > 0
  }, [tableFilters])

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionToViewAllClaims = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "LIST_ALL_CLAIMS" && p.access === "Delete",
      ),
    [user],
  )

  const {
    data: claimsData,
    isLoading: areClaimsLoading,
    refetch: refetchClaims,
    isRefetching: isClaimsRefetching,
  } = useQuery({
    queryKey: ["claim-listing"],
    queryFn: () => getClaimsListingAPI(endpointParams),
  })

  const { mutate: createClaim, isPending: isClaimCreating } = useMutation({
    mutationFn: () =>
      createClaimAPI({ body: {}, params: { groupId: appState?.groupId! } }),
    onSuccess: (response) => {
      client.setQueryData(["claim-details", response.id], response)
      navigate(`/claims/${response.id}`, {
        state: { isEditModeEnabled: true, shouldSetDefaultValues: true },
      })
    },
  })

  const { mutate: updateGroupFilters } = useMutation({
    mutationFn: (body: IClaimListingFilters) =>
      updateSearchFiltersAPI({
        params: {
          groupId: appState?.groupId!,
        },
        body,
      }),
    onSuccess: (response) => {
      client.setQueryData(["user"], {
        ...user,
        filters: response,
      })
    },
  })

  useEffect(() => {
    if (user) setTableFilters(user.filters)
  }, [user])

  useEffect(() => {
    void refetchClaims()
  }, [endpointParams])

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKey(checkIfDate(event.target.value))
      setPage(0)
    },
    [],
  )

  const handleViewAllClaimsChange = useCallback(() => {
    if (hasPermissionToViewAllClaims) {
      setEndpointParams((prevState) => {
        const nextValue = !prevState.viewAllClaims
        localStorage.setItem("showAllClaims", JSON.stringify(nextValue))
        return {
          ...prevState,
          viewAllClaims: nextValue,
        }
      })
    }
  }, [hasPermissionToViewAllClaims])

  const filteredArray = useMemo(() => {
    return claimsData?.claims?.filter((claim) => {
      if (
        (tableFilters?.caseNumber &&
          !claim?.caseNumber
            ?.toLowerCase()
            .includes(tableFilters?.caseNumber.toLowerCase())) ||
        (tableFilters?.officialMandantRegistrationNumber &&
          !claim?.officialMandantRegistrationNumber
            ?.toLowerCase()
            ?.includes(
              tableFilters.officialMandantRegistrationNumber.toLowerCase(),
            )) ||
        (tableFilters?.officialOpponentRegistrationNumber &&
          !claim?.officialOpponentRegistrationNumber
            ?.toLowerCase()
            ?.includes(
              tableFilters.officialOpponentRegistrationNumber.toLowerCase(),
            )) ||
        (tableFilters?.groupName &&
          !claim.groupName
            ?.toLowerCase()
            ?.includes(tableFilters.groupName.toLowerCase())) ||
        (tableFilters?.damagedParty &&
          !claim.damagedParty
            ?.toLowerCase()
            ?.includes(tableFilters.damagedParty.toLowerCase())) ||
        (tableFilters?.opponentParty &&
          !claim.opponentParty
            ?.toLowerCase()
            ?.includes(tableFilters.opponentParty.toLowerCase())) ||
        (tableFilters?.clerkDetails &&
          !claim.clerkDetails
            ?.toLowerCase()
            ?.includes(tableFilters.clerkDetails.toLowerCase())) ||
        (tableFilters?.accidentPlace &&
          !claim.accidentPlace
            ?.toLowerCase()
            ?.includes(tableFilters.accidentPlace.toLowerCase())) ||
        (tableFilters?.showCasesWithoutLiabilityQuota === false &&
          claim.liabilityQuota === null) ||
        (tableFilters?.minLiability !== null &&
          tableFilters?.minLiability !== undefined &&
          tableFilters.minLiability > claim.liabilityQuota) ||
        (tableFilters?.maxLiability !== null &&
          tableFilters?.maxLiability !== undefined &&
          claim.liabilityQuota !== null &&
          tableFilters.maxLiability < claim.liabilityQuota) ||
        (!!tableFilters?.status?.length &&
          !tableFilters?.status?.includes(claim.status)) ||
        (!!tableFilters?.liability?.length &&
          !tableFilters?.liability?.includes(claim.liability)) ||
        (tableFilters?.createdStartDate &&
          tableFilters?.createdEndDate &&
          (dayjs(claim.createdDate).isBefore(
            dayjs(tableFilters.createdStartDate),
          ) ||
            dayjs(claim.createdDate).isAfter(
              dayjs(tableFilters?.createdEndDate),
            ))) ||
        (tableFilters?.createdStartDate &&
          !tableFilters?.createdEndDate &&
          !dayjs(claim.createdDate).isAfter(
            dayjs(tableFilters.createdStartDate),
          ) &&
          !dayjs(claim.createdDate).isSame(
            dayjs(tableFilters?.createdStartDate),
          )) ||
        (tableFilters?.createdEndDate &&
          !tableFilters?.createdStartDate &&
          !dayjs(claim.createdDate).isBefore(
            dayjs(tableFilters.createdEndDate),
          ) &&
          !dayjs(claim.createdDate).isSame(
            dayjs(tableFilters?.createdEndDate),
          )) ||
        ((tableFilters?.accidentStartDate || tableFilters?.accidentEndDate) &&
          !claim.accidentDate) ||
        (tableFilters?.accidentStartDate &&
          tableFilters?.accidentEndDate &&
          claim.accidentDate &&
          (dayjs(claim.accidentDate).isBefore(
            dayjs(tableFilters.accidentStartDate),
          ) ||
            dayjs(claim.accidentDate).isAfter(
              dayjs(tableFilters?.accidentEndDate),
            ))) ||
        (tableFilters?.accidentStartDate &&
          !tableFilters?.accidentEndDate &&
          !dayjs(claim.accidentDate).isAfter(
            dayjs(tableFilters.accidentStartDate),
          ) &&
          !dayjs(claim.accidentDate).isSame(
            dayjs(tableFilters?.accidentStartDate),
          )) ||
        (tableFilters?.accidentEndDate &&
          !tableFilters?.accidentStartDate &&
          !dayjs(claim.accidentDate).isBefore(
            dayjs(tableFilters.accidentEndDate),
          ) &&
          !dayjs(claim.createdDate).isSame(
            dayjs(tableFilters?.accidentEndDate),
          )) ||
        (searchKey &&
          !claim.caseNumber?.toLowerCase().includes(searchKey.toLowerCase()) &&
          !claim.officialMandantRegistrationNumber
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.officialOpponentRegistrationNumber
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.damagedParty
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.opponentParty
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.clerkDetails
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.accidentPlace
            ?.toLowerCase()
            .includes(searchKey.toLowerCase()) &&
          !claim.createdDate?.includes(searchKey) &&
          !claim.accidentDate?.includes(searchKey) &&
          !claim.groupName?.toLowerCase().includes(searchKey.toLowerCase()))
      ) {
        return false
      }

      return true
    })
  }, [claimsData?.claims, searchKey, tableFilters])

  const handleFiltering = useCallback(
    (filters: IFilter, updateOnBackend: boolean) => {
      const transformedFilters = {
        caseNumber: filters.caseNumber,
        officialMandantRegistrationNumber:
          filters.officialMandantRegistrationNumber,
        officialOpponentRegistrationNumber:
          filters.officialOpponentRegistrationNumber,
        groupName: filters.groupName,
        damagedParty: filters.damagedParty,
        opponentParty: filters.opponentParty,
        clerkDetails: filters.clerkDetails,
        accidentPlace: filters.accidentPlace,
        createdStartDate: filters.dates.createdStartDate?.toISOString() ?? null,
        createdEndDate: filters.dates.createdEndDate?.toISOString() ?? null,
        accidentStartDate:
          filters.dates.accidentStartDate?.toISOString() ?? null,
        accidentEndDate: filters.dates.accidentEndDate?.toISOString() ?? null,
        status: filters.status?.length ? filters.status : null,
        liability: filters.liability?.length ? filters.liability : null,

        maxLiability: filters.maxLiability ?? null,
        minLiability: filters.minLiability ?? null,
        showCasesWithoutLiabilityQuota:
          filters.showCasesWithoutLiabilityQuota === true
            ? null
            : filters.showCasesWithoutLiabilityQuota,
      }

      setTableFilters(transformedFilters)
      setPage(0)
      if (updateOnBackend) updateGroupFilters(transformedFilters)
    },
    [],
  )

  if (areClaimsLoading) {
    return (
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          paddingX="24px"
          paddingTop="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("claimListing")}
          </Typography>

          <FilterPopup
            showAllGroups={endpointParams.viewAllClaims}
            initialFilters={tableFilters}
            onFilterAll={handleFiltering}
          />
          <TextField
            variant="outlined"
            placeholder={t("search")}
            fullWidth={isSmallerThanLg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <LoadingButton
            onClick={() => createClaim()}
            loading={isClaimCreating}
            startIcon={<Add />}
          >
            {t("newClaim")}
          </LoadingButton>
        </Box>
        <Box
          display="flex"
          alignItems={isSmallerThanLg ? "flex-end" : "center"}
          gap="8px"
          flexDirection={isSmallerThanLg ? "column" : "row"}
          justifyContent="flex-end"
          padding="0px 24px"
        >
          {hasPermissionToViewAllClaims && (
            <SwitchControlLabel
              control={
                <Switch
                  checked={endpointParams.viewAllClaims}
                  onChange={handleViewAllClaimsChange}
                />
              }
              label={t("showAllGroups")}
              labelPlacement="end"
            />
          )}
        </Box>
        {tableIsFiltered && <ActiveFiltersBar tableFilters={tableFilters} />}
        <Box display="grid" paddingBottom="24px">
          <ClaimsTable
            claims={filteredArray}
            claimsRefetching={isClaimsRefetching}
            showClaimGroup={endpointParams.viewAllClaims}
            page={page}
            setPage={setPage}
          />
        </Box>
      </Box>
    </Box>
  )
}
