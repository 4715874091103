import { useState } from "react"
import { Box } from "@mui/material"

import { VehicleDetailsSection } from "../VehicleDetailsSection"
import { CreateVehicleSection } from "../CreateVehicleSection"

interface IProps {
  selectedVehicle?: any
  onClose: () => void
  onDeleteClick?: (vehicle: IVehicle) => void
}

export const VehicleEditDetails = (props: IProps) => {
  const { selectedVehicle, onClose, onDeleteClick } = props

  const [isEditing, setIsEditing] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleEditClick = (tabIndex: number) => {
    setSelectedTab(tabIndex)
    setIsEditing(true)
  }

  return (
    <Box>
      {isEditing ? (
        <CreateVehicleSection
          selectedVehicle={selectedVehicle}
          defaultTab={selectedTab}
          onClose={onClose}
        />
      ) : (
        <VehicleDetailsSection
          selectedVehicle={selectedVehicle}
          onEditClick={handleEditClick}
          onDeleteClick={onDeleteClick}
        />
      )}
    </Box>
  )
}
