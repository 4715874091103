import React, { useCallback, useEffect } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import {
  type Control,
  Controller,
  type FieldValues,
  useForm,
} from "react-hook-form"
import { useToast } from "../../contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createOrganizationAPI } from "../../services"
import { PlaceController } from "../../pages/ClaimPage/components/FormField/components"
import { PhoneNumberInput } from "../PhoneNumberInput"
import { isValidPhoneNumber } from "react-phone-number-input"
import {
  convertEmptyPropertiesToNull,
  EMAIL_REGEX_VALIDATION,
  NUMERIC_REGEX_VALIDATION,
} from "../../utils"

interface CreateOrganizationModalProps {
  open: boolean
  onClose: () => void
}

const CreateOrganizationModal: React.FC<CreateOrganizationModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<IOrganizationManagementBody>({
    defaultValues: {
      name: "",
      organizationType: "",
      email: "",
      phone: "",
      houseNumber: "",
      address: {
        postCodePlace: undefined,
        street: "",
      },
      externalDetails: {
        addressNr: undefined,
        referat: undefined,
        lawyer: "",
        contactPersonFullName: "",
        contactPersonId: undefined,
      },
      bankDetails: {
        ibanOrAccountNo: null,
        bicOrBankCode: null,
        fullName: null,
      },
    },
  })

  useEffect(() => {
    if (open) {
      reset({
        name: "",
        houseNumber: "",
        organizationType: "",
        email: "",
        phone: "",
        address: {
          postCodePlace: undefined,
          street: "",
        },
        externalDetails: {
          addressNr: undefined,
          referat: undefined,
          lawyer: "",
          contactPersonFullName: "",
          contactPersonId: undefined,
        },
        bankDetails: {
          ibanOrAccountNo: null,
          bicOrBankCode: null,
          fullName: null,
        },
      })
    }
  }, [open, reset])

  const { mutate: createOrganization, isPending: isCreating } = useMutation({
    mutationFn: (data) => createOrganizationAPI(data as any),
    onSuccess: () => {
      toast.show(t("organizationCreated"), "success")
      void queryClient.refetchQueries({
        queryKey: ["organizations-management"],
      })
      onClose()
    },
  })

  const validatePhoneNumber = useCallback((value: string) => {
    if (!isValidPhoneNumber(value)) {
      return t("invalidPhoneNumberErrorMessage")
    } else {
      return true
    }
  }, [])

  const onFormSubmit = (data: any) => {
    const filteredValues = convertEmptyPropertiesToNull(data)
    createOrganization(filteredValues)
  }

  return (
    <Dialog
      component="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <DialogTitle>{t("createOrganization")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Controller
            name="name"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("organizationName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />

          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.organizationType}
          >
            <InputLabel>{t("organizationType")}</InputLabel>
            <Controller
              name="organizationType"
              control={control}
              rules={{ required: t("required") }}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("organizationType")}
                  value={value}
                  onChange={onChange}
                  fullWidth
                >
                  <MenuItem value="Autohaus">{t("autohaus")}</MenuItem>
                  <MenuItem value="CarPool">{t("carpool")}</MenuItem>
                  <MenuItem value="Expert">{t("expert")}</MenuItem>
                  <MenuItem value="Workshop">{t("workshop")}</MenuItem>
                  <MenuItem value="LegalOffice">{t("legalOffice")}</MenuItem>
                </Select>
              )}
            />
            {errors.organizationType && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </FormControl>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: t("required"),
              validate: validatePhoneNumber,
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneNumberInput
                fullWidth
                value={value}
                onChange={onChange}
                label={t("mobilePhone")}
                error={!!errors?.phone}
                helperText={errors?.phone?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("required"),
              pattern: {
                value: EMAIL_REGEX_VALIDATION,
                message: t("emailNotValid"),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t("email")}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            )}
          />
          <Typography
            marginTop="8px"
            marginBottom="-8px"
            variant="smallSemiBold"
          >
            {t("address")}
          </Typography>
          <Controller
            name="address.street"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("street")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <PlaceController
            formControl={control as unknown as Control<FieldValues>}
            field={
              {
                controlName: "address.postCodePlace",
                label: t("postalCodeOrCity"),
              } as any
            }
            disabled={false}
            rules={{ required: t("required") }}
          />
          <Controller
            name="houseNumber"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("houseNumber")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Typography
            marginTop="8px"
            marginBottom="-8px"
            variant="smallSemiBold"
          >
            {t("advowareSettings")}
          </Typography>
          <Controller
            name="externalDetails.addressNr"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("required"),
              },
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("addressNr")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.referat"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("required"),
              },
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("referat")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.lawyer"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("lawyer")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonFullName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("contactPersonFullName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonId"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("contactPersonId")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Typography
            marginTop="8px"
            marginBottom="-8px"
            variant="smallSemiBold"
          >
            {t("bankDetails")}
          </Typography>
          <Controller
            name="bankDetails.ibanOrAccountNo"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: t("minLengthErrorMessage", {
                  value: 2,
                }),
              },
              maxLength: {
                value: 34,
                message: t("maxLengthErrorMessage", { value: 34 }),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                label={t("iban")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="bankDetails.bicOrBankCode"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: t("minLengthErrorMessage", {
                  value: 2,
                }),
              },
              maxLength: {
                value: 12,
                message: t("maxLengthErrorMessage", { value: 12 }),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                label={t("bicBankCode")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="bankDetails.fullName"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: t("minLengthErrorMessage", {
                  value: 2,
                }),
              },
              maxLength: {
                value: 50,
                message: t("maxLengthErrorMessage", { value: 50 }),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                label={t("accountHolderName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton type="submit" loading={isSubmitting || isCreating}>
          {t("create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrganizationModal
