import { axios } from "../lib"

export const getOrganizationsAPI = (
  params?: IGetOrganizationsParams,
): Promise<IOrganization[]> => axios.get("Group/groups", { params })

export const switchActiveGroupAPI = (id: string): Promise<IOrganization[]> =>
  axios.put(`Group/switch-active-group?groupId=${id}`)

export const createGroupAPI = (body: ICreateGroupBody): Promise<IGroup> =>
  axios.post("Group", body)

export const updateGroupAPI = (
  id: string,
  body: ICreateGroupBody,
): Promise<IGroup> => axios.put(`Group/${id}`, body)

export const deleteGroupAPI = (id: string): Promise<void> =>
  axios.delete(`Group/group?groupId=${id}`)

export const getOrganizationsManagementAPI = (): Promise<
  IOrganizationManagement[]
> => axios.get("Organization/organizations")

export const createOrganizationAPI = (
  body: IOrganizationManagementBody,
): Promise<IGroup> => axios.post("Organization", body)

export const updateOrganizationAPI = (
  id: string,
  body: IOrganizationManagementBody,
): Promise<IOrganization[]> => axios.put(`Organization/${id}`, body)

export const deleteOrganizationAPI = (id: string): Promise<void> =>
  axios.delete(`Organization/${id}`)

export const updateAllowedGroupsAPI = (
  requestParams: IUpdateAllowedGroupsRequestParams,
): Promise<IGroup> =>
  axios.put(`Group/${requestParams.groupId}/allowed-groups`, requestParams.body)

export const getEmailConfigurationsAPI = (
  id: string,
): Promise<IEmailConfiguration[]> => axios.get(`Email/email-config/${id}`)

export const getAvailableFootersAPI = (): Promise<IFooter[]> =>
  axios.get("Email/email-footers")

export const updateEmailConfigurationsAPI = (
  requestParams: IUpdateEmailConfigurationRequestParams,
): Promise<IEmailConfiguration[]> =>
  axios.post(`Email/email-config/${requestParams.id}`, requestParams.body)

export const sendTestEmailAPI = (params: ITestEmailParams): Promise<void> =>
  axios.post("Email/test-email", null, { params })
