import { Box, styled, TextField, IconButton } from "@mui/material"
import { colors } from "../../../../../../utils"

export const EntriesWrapper = styled(Box)`
  display: flex;
  width: 100%;
  overflow: auto;
  gap: 12px;
  padding-bottom: 12px;
  padding-top: 8px;
`

export const EntryCard = styled(Box)`
  min-width: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 16px 12px;
  background-color: ${colors.gray8};
  border: 1px solid ${colors.gray5};
  border-radius: 12px;
`

export const AddEntryButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 8px ${colors.gray2};
  }
`

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  mb: 1.5;
`

export const FieldsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  mb: 12px;
`

export const CustomizedTextField = styled(TextField)`
  background-color: white;
  margin-top: 8px;
`

export const DeleteIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
`
