import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import {
  getClaimTemplateAPI,
  getOrganizationsAPI,
  getUserAPI,
  loginAPI,
  refreshTokenAPI,
  resetPasswordAPI,
  signupInvitationAPI,
  verifyOtpAPI,
} from "../../services"
import { axios, axiosWorkflow } from "../../lib"
import { findGroup, getWorkflowApiKey } from "../../utils"

type TLoginMutation = UseMutationResult<
  ILoginResponse,
  Error,
  ILoginRequestBody,
  unknown
>

type IRefreshTokenMutation = UseMutationResult<
  ILoginResponse,
  Error,
  void,
  unknown
>

type TResetPasswordMutation = UseMutationResult<
  ILoginResponse,
  Error,
  IResetPasswordBody,
  unknown
>

type TVerifyOtpMutation = UseMutationResult<
  ILoginResponse | string,
  Error,
  IVerifyOtpBody,
  unknown
>

type TSignupInvitationMutation = UseMutationResult<
  ILoginResponse,
  Error,
  ISignupInvitationRequestBody,
  unknown
>

interface IProps {
  children?: React.ReactNode
}

interface IAppState {
  authorization: TAuthorization
  token?: string
  refreshToken?: string
  groupId?: string
  trackingAuthorization?: boolean
}

interface IAppContextValue {
  state: IAppState
  refreshTokenMutation: IRefreshTokenMutation
  loginMutation: TLoginMutation
  resetPasswordMutation: TResetPasswordMutation
  verifyOtpMutation: TVerifyOtpMutation
  mutateRegisterInvitation: TSignupInvitationMutation
  setTrackingAuthorization: (value: boolean) => void
  logOut: () => void
}

const AppContext = createContext<IAppContextValue>(undefined!)

export const useAppContext = () => useContext(AppContext)

const initialState: IAppState = {
  authorization: "uninitialized",
  token: undefined,
  refreshToken: undefined,
  groupId: undefined,
  trackingAuthorization: false,
}

export const AppContextProvider = (props: IProps) => {
  const { children } = props
  const queryClient = useQueryClient()
  const [state, setState] = useState(initialState)

  const setTrackingAuthorization = useCallback(
    (value: boolean) =>
      setState((prevState) => ({
        ...prevState,
        trackingAuthorization: value,
      })),
    [],
  )

  const setApiKeyHeader = useCallback(() => {
    const apiKey = getWorkflowApiKey()
    axiosWorkflow.defaults.headers.ApiKey = apiKey
  }, [])

  const onAppLoad = useCallback(async () => {
    try {
      const token =
        localStorage.getItem("token") ??
        sessionStorage.getItem("token") ??
        undefined
      const refreshToken =
        localStorage.getItem("refreshToken") ??
        sessionStorage.getItem("refreshToken") ??
        undefined

      setState((prevState) => ({
        ...prevState,
        token,
        refreshToken,
      }))

      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`

        const user = await queryClient.fetchQuery({
          queryKey: ["user"],
          queryFn: () => getUserAPI(),
        })

        const sessionGroupId = sessionStorage.getItem("groupId") || undefined

        let groupId = sessionGroupId

        if (sessionGroupId) {
          const organizations = await queryClient.fetchQuery({
            queryKey: ["organizations"],
            queryFn: () => getOrganizationsAPI(),
          })

          const groups = organizations?.flatMap((o) => o.groups)

          if (groups?.length) {
            const isGroupFound = findGroup(groups, groupId!)

            if (!isGroupFound) {
              sessionStorage.removeItem("groupId")

              groupId = undefined
            }
          }
        } else if (user?.groupId) {
          sessionStorage.setItem("groupId", user.groupId)
          groupId = user.groupId

          void queryClient.prefetchQuery({
            queryKey: ["claim-template", groupId],
            queryFn: () => getClaimTemplateAPI({ groupId }),
          })
        }

        setState((prevState) => ({
          ...prevState,
          authorization: "authorized",
          groupId,
        }))

        setApiKeyHeader()
      } else {
        throw Error()
      }
    } catch (_) {
      setState({
        ...initialState,
        authorization: "unauthorized",
      })
    }
  }, [queryClient, setApiKeyHeader])

  const updateAuthorization = useCallback(
    async (
      response: ILoginResponse,
      setAuthorized = false,
      prefetchUser = false,
    ) => {
      if (response.isKeepMeLoggedInEnabled) {
        localStorage.setItem("token", response.accessToken)
        localStorage.setItem("refreshToken", response.refreshToken)
      } else {
        sessionStorage.setItem("token", response.accessToken)
        sessionStorage.setItem("refreshToken", response.refreshToken)
      }

      axios.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`

      let nextState: IAppState = {
        ...state,
        token: response.accessToken,
        refreshToken: response.refreshToken,
      }

      if (setAuthorized) {
        setApiKeyHeader()
        nextState = {
          ...nextState,
          authorization: "authorized",
        }
      }

      if (prefetchUser) {
        const user = await queryClient.fetchQuery({
          queryKey: ["user"],
          queryFn: () => getUserAPI(),
        })
        sessionStorage.setItem("groupId", user?.groupId)
        nextState = {
          ...nextState,
          groupId: user?.groupId,
        }

        if (user?.groupId) {
          void queryClient.prefetchQuery({
            queryKey: ["claim-template", user?.groupId],
            queryFn: () => getClaimTemplateAPI({ groupId: user?.groupId }),
          })
        }
      }

      setState(nextState)
    },
    [state],
  )

  const loginMutation: TLoginMutation = useMutation({
    mutationFn: async (body: ILoginRequestBody) => {
      const response = await loginAPI(body)
      await updateAuthorization(response, true, true)

      return response
    },
  })

  const refreshTokenMutation: IRefreshTokenMutation = useMutation({
    mutationFn: async () => {
      delete axios.defaults.headers.common.Authorization
      const response = await refreshTokenAPI({
        token: state.token,
        refreshToken: state.refreshToken,
      })
      await updateAuthorization(response)
      return response
    },
  })

  const resetPasswordMutation: TResetPasswordMutation = useMutation({
    mutationFn: async (body: IResetPasswordBody) => {
      const response = await resetPasswordAPI(body)
      await updateAuthorization(response, true, true)
      return response
    },
  })

  const verifyOtpMutation: TVerifyOtpMutation = useMutation({
    mutationFn: async (body: IVerifyOtpBody) => {
      const response = await verifyOtpAPI(body)
      if (typeof response === "object" && response !== null) {
        await updateAuthorization(response, true, true)
      }
      return response
    },
  })

  const mutateRegisterInvitation: TSignupInvitationMutation = useMutation({
    mutationFn: async (body: ISignupInvitationRequestBody) => {
      const response = await signupInvitationAPI(body)
      if (typeof response === "object" && response !== null) {
        await updateAuthorization(response, true, true)
      }
      return response
    },
  })

  const logOut = useCallback(() => {
    setState({
      ...initialState,
      authorization: "unauthorized",
    })
    queryClient.clear()
    localStorage.clear()
    sessionStorage.clear()
    delete axios.defaults.headers.common.Authorization
  }, [])

  const value = {
    state,
    loginMutation,
    refreshTokenMutation,
    resetPasswordMutation,
    verifyOtpMutation,
    mutateRegisterInvitation,
    setTrackingAuthorization,
    logOut,
  }

  useEffect(() => {
    void onAppLoad()
  }, [])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
