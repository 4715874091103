import { useTranslation } from "react-i18next"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useMemo, useState } from "react"
import { getOrganizationsAPI } from "../../services"
import { GroupSwitchDialog } from "../../components"

export const NoActiveGroupPage = () => {
  const { t } = useTranslation()
  const [isGroupSwitcherDialogVisible, setGroupSwitcherDialogVisble] =
    useState(false)

  const { data: organizations, isLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const hasGroup = useMemo(
    () => !!organizations?.flatMap((o) => o.groups)?.length,
    [organizations],
  )

  const onSelectGroupClick = useCallback(
    () => setGroupSwitcherDialogVisble(true),
    [],
  )

  const onCloseGroupSwitcher = useCallback(
    () => setGroupSwitcherDialogVisble(false),
    [],
  )

  return (
    <Box
      flex={1}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="24px"
    >
      <GroupSwitchDialog
        open={isGroupSwitcherDialogVisible}
        closeDialog={onCloseGroupSwitcher}
      />
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography
            variant="largeSemiBold"
            marginBottom="24px"
            textAlign="center"
          >
            {hasGroup ? t("noActiveGroupAlert") : t("noGroupsFound")}
          </Typography>
          {hasGroup && (
            <Button onClick={onSelectGroupClick}>{t("selectGroup")}</Button>
          )}
        </>
      )}
    </Box>
  )
}
