import { Box, Typography, Button } from "@mui/material"
import {
  ModeEditOutlineOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material"
import { colors, VEHICLE_TYPE_OPTIONS } from "../../../../utils"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { VehicleAttributeDisplay } from "./components/VehicleAttributeDisplay"

interface IProps {
  selectedVehicle: IVehicle
  onEditClick: (tabIndex: number) => void
  onDeleteClick?: (vehicle: IVehicle) => void
}

export const VehicleDetailsSection = (props: IProps) => {
  const { selectedVehicle, onEditClick, onDeleteClick } = props

  const {
    vehicleInformation,
    holder,
    leasingFinancing,
    insuranceInformation,
    fullPartialComprehensiveInsurance,
  } = selectedVehicle

  const { t } = useTranslation()

  const columnStyle = {
    flex: 1,
    padding: "0 16px",
  }

  const salutations = [
    { name: "Company", value: "Firma" },
    { name: "Mrs.", value: "Frau" },
    { name: "Mr.", value: "Herr" },
  ]

  const vehicleTypeLabel = VEHICLE_TYPE_OPTIONS.find(
    (option) => option?.value === vehicleInformation?.vehicleTypeSelection,
  )?.label

  return (
    <Box marginX="24px">
      <Box display="flex" justifyContent="space-between">
        <Box sx={columnStyle}>
          <Typography variant="extraLargeMedium" gutterBottom>
            {t("vehicleData")}
          </Typography>
          <VehicleAttributeDisplay
            label="vehicleType"
            value={t(vehicleTypeLabel as string)}
          />
          {vehicleInformation?.vehicleTypeSelection === "Andere" && (
            <VehicleAttributeDisplay
              label="vehicleTypeOther"
              value={vehicleInformation?.vehicleType}
            />
          )}
          <VehicleAttributeDisplay
            label="officialRegistrationNumber"
            value={vehicleInformation?.officialRegistrationNumber}
          />
          <VehicleAttributeDisplay
            label="chasisNumber"
            value={vehicleInformation?.chassisNumber}
          />
          <VehicleAttributeDisplay
            label="manufacturer"
            value={vehicleInformation?.manufacturer}
          />
          <VehicleAttributeDisplay
            label="manufacturerType"
            value={vehicleInformation?.manufacturerType}
          />
          <VehicleAttributeDisplay
            label="initialRegistration"
            value={dayjs(vehicleInformation?.initialRegistration).format(
              "DD.MM.YYYY",
            )}
          />
          <Button
            onClick={() => onEditClick(0)}
            variant="text"
            startIcon={<ModeEditOutlineOutlined />}
            sx={{
              color: `${colors.primary}`,
              fontWeight: "normal",
            }}
          >
            {t("edit")}
          </Button>
        </Box>

        <Box sx={columnStyle}>
          <Typography variant="extraLargeMedium" gutterBottom>
            {t("holder")}
          </Typography>
          <VehicleAttributeDisplay
            label="salutation"
            value={holder?.salutation}
            salutations={salutations}
          />
          {holder?.salutation === "Firma" ? (
            <>
              <VehicleAttributeDisplay
                label="Company"
                value={holder?.company}
              />
              <VehicleAttributeDisplay
                label="companyPreciseRepresentationRelationship"
                value={holder?.companyPreciseRepresentationRelationship}
              />
            </>
          ) : (
            <>
              <VehicleAttributeDisplay label="title" value={holder?.title} />
              <VehicleAttributeDisplay
                label="firstName"
                value={holder?.firstName}
              />
              <VehicleAttributeDisplay
                label="lastName"
                value={holder?.lastName}
              />
            </>
          )}
          <VehicleAttributeDisplay
            label="street"
            value={holder.address?.street}
          />
          <VehicleAttributeDisplay
            label="postCode"
            value={`${holder.address?.postCodePlace?.place || ""}, ${
              holder.address?.postCodePlace?.postCode || ""
            }`.trim()}
          />
          <VehicleAttributeDisplay
            label="phoneNumber"
            value={holder?.phoneNumber}
          />
          <VehicleAttributeDisplay label="email" value={holder?.email} />
          <Button
            onClick={() => onEditClick(1)}
            variant="text"
            startIcon={<ModeEditOutlineOutlined />}
            sx={{
              color: `${colors.primary}`,
              fontWeight: "normal",
            }}
          >
            {t("edit")}
          </Button>
        </Box>

        <Box sx={columnStyle}>
          <Typography variant="extraLargeMedium" gutterBottom>
            {t("leasingFinancing")}
          </Typography>
          <VehicleAttributeDisplay
            label="Leasing"
            value={t(leasingFinancing?.leasingOrFinancingOrVLeasing)}
          />
          <VehicleAttributeDisplay
            label="contractNumber"
            value={leasingFinancing?.versNo}
          />
          <VehicleAttributeDisplay
            label="lessorBank"
            value={leasingFinancing?.lessor}
          />
          <Button
            onClick={() => onEditClick(2)}
            variant="text"
            startIcon={<ModeEditOutlineOutlined />}
            sx={{
              color: `${colors.primary}`,
              fontWeight: "normal",
            }}
          >
            {t("edit")}
          </Button>
        </Box>

        <Box sx={columnStyle}>
          <Typography variant="extraLargeMedium" gutterBottom>
            {t("liabilityInsurance")}
          </Typography>
          <VehicleAttributeDisplay
            label="insurer"
            value={insuranceInformation?.insurer}
          />
          <VehicleAttributeDisplay
            label="insuranceNumber"
            value={insuranceInformation?.insuranceNo}
          />
          <Button
            onClick={() => onEditClick(3)}
            variant="text"
            startIcon={<ModeEditOutlineOutlined />}
            sx={{
              color: `${colors.primary}`,
              fontWeight: "normal",
            }}
          >
            {t("edit")}
          </Button>
        </Box>

        <Box sx={columnStyle}>
          <Typography variant="extraLargeMedium" gutterBottom>
            {t("fullPartialInsurance")}
          </Typography>
          <VehicleAttributeDisplay
            label="fullCoverage"
            value={
              fullPartialComprehensiveInsurance?.fullyComprehensiveInsurance
            }
          />
          <VehicleAttributeDisplay
            label="deductible"
            value={fullPartialComprehensiveInsurance?.deductible}
          />
          <VehicleAttributeDisplay
            label="partialCoverage"
            value={fullPartialComprehensiveInsurance?.partialCover}
          />
          <VehicleAttributeDisplay
            label="deductible"
            value={fullPartialComprehensiveInsurance?.selfParticipation}
          />
          <VehicleAttributeDisplay
            label="insurerInsurance"
            value={
              fullPartialComprehensiveInsurance?.insuranceInformation?.insurer
            }
          />
          <VehicleAttributeDisplay
            label="insurerInsuranceNo"
            value={
              fullPartialComprehensiveInsurance?.insuranceInformation
                ?.insuranceNo
            }
          />
          <Button
            onClick={() => onEditClick(4)}
            variant="text"
            startIcon={<ModeEditOutlineOutlined />}
            sx={{
              color: `${colors.primary}`,
              fontWeight: "normal",
            }}
          >
            {t("edit")}
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        borderTop={`1px solid ${colors.gray10}`}
        justifyContent="center"
        mt="24px"
        pt="8px"
        gap={2}
      >
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onDeleteClick?.(selectedVehicle)
          }}
          variant="text"
          startIcon={<DeleteOutlineOutlined />}
          sx={{
            color: `${colors.red}`,
            fontWeight: "normal",
          }}
        >
          {t("deleteVehicle")}
        </Button>
      </Box>
    </Box>
  )
}
