import React, { useState } from "react"
import { Box, Tabs, Tab, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { VehicleFinancingTab } from "./components/VehicleFinancingTab"
import { VehicleCoverageTab } from "./components/VehicleCoverageTab"
import { colors } from "../../../../utils"
import { useForm } from "react-hook-form"
import { useAppContext, useToast } from "../../../../contexts"
import { createVehicleAPI, updateVehicleAPI } from "../../../../services"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  SaveOutlined,
  ErrorOutline,
  HighlightOffOutlined,
} from "@mui/icons-material"
import { VehicleDataTab } from "./components/VehicleDataTab"
import { VehicleHolderTab, VehicleInsuranceTab } from "./components"
import { LoadingButton } from "@mui/lab"

interface CreateVehicleSectionProps {
  selectedVehicle?: IVehicle
  defaultTab: number
  onClose: () => void
}

export const CreateVehicleSection: React.FC<CreateVehicleSectionProps> = ({
  selectedVehicle,
  defaultTab,
  onClose,
}) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(defaultTab)
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    state: { groupId },
  } = useAppContext()

  const methods = useForm({
    defaultValues: {
      vehicleInformation: selectedVehicle?.vehicleInformation || {},
      holder: selectedVehicle?.holder || {},
      leasingFinancing: selectedVehicle?.leasingFinancing || {},
      insuranceInformation: selectedVehicle?.insuranceInformation || {},
      fullPartialComprehensiveInsurance:
        selectedVehicle?.fullPartialComprehensiveInsurance || {},
    },
  })

  const {
    handleSubmit,
    trigger,
    getValues,
    formState: { isSubmitting, errors },
  } = methods

  const handleChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    setActiveTab(newValue)
  }

  const { mutate: createVehicle, isPending: isVehicleCreating } = useMutation({
    mutationFn: (body: ICreateVehicleBody) => createVehicleAPI(body),
    onSuccess: () => {
      toast.show(t("vehicleCreatedSuccessfullyMessage"), "success")

      void queryClient.refetchQueries({ queryKey: ["vehicles"] })
    },
    onSettled: () => {
      onClose()
    },
  })

  const { mutate: updateVehicle, isPending: isVehicleUpdating } = useMutation({
    mutationFn: (requestParams: IUpdateVehicleRequestParams) =>
      updateVehicleAPI(requestParams),
    onSuccess: (response) => {
      toast.show(t("vehicleUpdatedSuccessfullyMessage"), "success")

      queryClient.setQueryData(["vehicles"], (prev: IVehicles) => {
        const index = prev?.vehicles?.findIndex((v) => v.id === response.id)

        if (index !== -1) {
          return {
            ...prev,
            vehicles: [
              ...prev.vehicles.slice(0, index),
              { ...prev, ...response },
              ...prev.vehicles.slice(index + 1),
            ],
          }
        }

        return prev
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  const onSubmit = async () => {
    const tabKeys = tabs.map((tab) => tab.key)
    const validations = await Promise.all(
      tabKeys.map((key) => trigger(key as any)),
    )

    if (validations.every(Boolean)) {
      const payload = {
        ...getValues(),
        groupId,
      }

      if (selectedVehicle) {
        updateVehicle({
          id: selectedVehicle.id,
          body: payload as any,
        })
      } else {
        createVehicle(payload as any)
      }
    }
  }

  const isLoading = isSubmitting || isVehicleCreating || isVehicleUpdating

  const tabs = [
    {
      label: t("vehicleData"),
      key: "vehicleInformation",
      component: <VehicleDataTab isLoading={isLoading} methods={methods} />,
    },
    {
      label: t("holder"),
      key: "holder",
      component: <VehicleHolderTab methods={methods} />,
    },
    {
      label: t("leasingFinancing"),
      key: "leasingFinancing",
      component: <VehicleFinancingTab methods={methods} />,
    },
    {
      label: t("liabilityInsurance"),
      key: "insuranceInformation",
      component: <VehicleInsuranceTab methods={methods} />,
    },
    {
      label: t("fullPartialInsurance"),
      key: "fullPartialComprehensiveInsurance",
      component: <VehicleCoverageTab methods={methods} />,
    },
  ]
  const tabErrors = tabs.map(
    (tab) => !!Object.keys(errors).find((key) => key.startsWith(tab.key)),
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginX="24px"
      gap="16px"
      sx={{
        ...(!selectedVehicle && {
          border: `1px solid ${colors.primary}`,
          borderRadius: "10px",
          paddingBottom: "16px",
        }),
      }}
    >
      {!selectedVehicle && (
        <Box
          borderRadius="10px"
          bgcolor={`${colors.secondary}`}
          borderBottom={`1px solid ${colors.gray10}`}
          paddingTop="16px"
        >
          <Typography variant="h6" align="center" marginBottom="16px">
            {t("newVehicle")}
          </Typography>
        </Box>
      )}

      <Tabs
        style={{ justifyContent: "center", alignSelf: "center" }}
        variant="scrollable"
        value={activeTab}
        onChange={handleChange}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Box display="flex" alignItems="center" gap={1}>
                {tab.label}
                {tabErrors[index] && (
                  <ErrorOutline color="error" fontSize="small" />
                )}
              </Box>
            }
          />
        ))}
      </Tabs>

      {tabs.map((tab, index) => (
        <Box
          key={index}
          style={{ display: activeTab === index ? "block" : "none" }}
        >
          {tab.component}
        </Box>
      ))}

      <Box
        display="flex"
        borderTop={`1px solid ${colors.gray10}`}
        justifyContent="center"
        mt="24px"
        pt="8px"
        gap={2}
      >
        <Button
          onClick={onClose}
          variant="text"
          startIcon={<HighlightOffOutlined />}
          sx={{
            color: `${colors.gray9}`,
            fontWeight: "normal",
          }}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="text"
          startIcon={<SaveOutlined color="primary" />}
          sx={{
            color: `${colors.primary}`,
            fontWeight: "normal",
          }}
          loading={isLoading}
        >
          {t("save")}
        </LoadingButton>
      </Box>
    </Box>
  )
}
