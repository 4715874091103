import { useMemo } from "react"
import { Controller, type UseFormReturn } from "react-hook-form"
import { TextField, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getInsuranceCompaniesAPI } from "../../../../../../services"
import { AutocompleteController } from "../../../../../../components"

interface IProps {
  methods: UseFormReturn<any, any, undefined>
}

export const VehicleInsuranceTab = (props: IProps) => {
  const { methods } = props
  const { t } = useTranslation()

  const { control } = methods

  const {
    data: liabilityInsuranceCompanies,
    isLoading: areLiabilityInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["liability-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "Liability" }),
    refetchOnMount: true,
  })

  const liabilityInsuranceCompanyOptions = useMemo(
    () =>
      liabilityInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [liabilityInsuranceCompanies],
  )

  return (
    <Box
      alignItems="center"
      component="form"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box display="flex" flexDirection="column" gap="16px" width="50%">
        <Box display="flex" gap="8px">
          <AutocompleteController
            formControl={control}
            controlName="insuranceInformation.insurer"
            label={t("insurer")}
            options={liabilityInsuranceCompanyOptions}
            areOptionsLoading={areLiabilityInsuranceCompaniesLoading}
          />
        </Box>

        <Box display="flex" gap="8px">
          <Controller
            name="insuranceInformation.insuranceNo"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("insuranceNumber")} fullWidth />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}
