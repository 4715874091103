import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Salutation {
  name: string
  value: string
}

interface VehicleAttributeDisplayProps {
  label: string
  value: string | number | null | undefined
  salutations?: Salutation[]
}

export const VehicleAttributeDisplay = (
  props: VehicleAttributeDisplayProps,
) => {
  const { label, value, salutations } = props
  const { t } = useTranslation()

  let displayValue = value

  if (label === "salutation" && value && salutations) {
    const salutation = salutations.find((s) => s.value === value)
    displayValue = salutation ? salutation.name : value
  }

  return (
    <Box mb="24px">
      <Typography variant="small">{t(label)}:</Typography>
      <Box>
        <Typography variant="small">{displayValue || "-"}</Typography>
      </Box>
    </Box>
  )
}
