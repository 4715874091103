import LoadingButton from "@mui/lab/LoadingButton"
import AddIcon from "@mui/icons-material/Add"
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  // TableFooter,
  TableHead,
  // TablePagination,
  // TableRow,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { GroupBodyRow } from "../GroupBodyRow"
import { useCallback, useMemo, useState } from "react"
import { GroupHeadRow } from "../GroupHeadRow"
import { colors } from "../../../../utils"
import { useQuery } from "@tanstack/react-query"

interface IProps {
  group: IGroup
  hasPermissionToChangeAllowedGroups?: boolean
  onAddSubGroupClick?: (group: IGroup) => void
  onEditClick: (group: IGroup) => void
  onDeleteClick: (group: IGroup) => void
  onAllowedGroupsClick: (group: IGroup) => void
}

export const SubGroupsManagement = (props: IProps) => {
  const {
    group,
    hasPermissionToChangeAllowedGroups,
    onAddSubGroupClick,
    onEditClick,
    onDeleteClick,
    onAllowedGroupsClick,
  } = props
  const { t } = useTranslation()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState<keyof IGroup>("name")
  const [order, setOrder] = useState<"asc" | "desc">("asc")

  const subGroups = useMemo(() => group?.subGroups ?? [], [group])

  // const handleChangePage = useCallback(
  //   (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
  //     setPage(newPage),
  //   [],
  // )

  // const handleChangeRowsPerPage = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setRowsPerPage(parseInt(event.target.value, 10))
  //     setPage(0)
  //   },
  //   [],
  // )

  const descendingComparator = useCallback(
    (a: IGroup, b: IGroup, orderBy: keyof IGroup): number => {
      if (b[orderBy] < a[orderBy]) {
        return -1
      }
      if (b[orderBy] > a[orderBy]) {
        return 1
      }
      return 0
    },
    [],
  )

  const getComparator = useCallback(
    (order: TOrder, orderBy: keyof IGroup) =>
      order === "desc"
        ? (a: IGroup, b: IGroup) => descendingComparator(a, b, orderBy)
        : (a: IGroup, b: IGroup) => -descendingComparator(a, b, orderBy),
    [order, orderBy],
  )

  const onSortClick = useCallback((columnName: string) => {
    setOrderBy(columnName as keyof IGroup)
    setOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }, [])

  const visibleRows = useMemo(
    () => [...subGroups].sort(getComparator(order, orderBy)),
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    [
      subGroups,
      order,
      orderBy,
      // page,
      // rowsPerPage,
      getComparator,
    ],
  )

  const canCreateSubGroup = useMemo(
    () => !!user?.permissions?.some((p) => p.action === "GROUPS"),
    [user],
  )

  return (
    <Box paddingY="16px" paddingLeft="0px" display="flex" flexDirection="row">
      <Box
        borderLeft={`1px dashed ${colors.gray2}`}
        height="auto"
        display="flex"
        marginX="22px"
      />
      <Box flex={1}>
        {canCreateSubGroup && (
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              size="small"
              startIcon={<AddIcon />}
              onClick={() => onAddSubGroupClick?.(group)}
            >
              {t("addSubgroup")}
            </LoadingButton>
          </Box>
        )}
        {!visibleRows?.length && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="16px"
          >
            <Typography>{t("noSubgroups")}</Typography>
          </Box>
        )}
        {!!visibleRows?.length && (
          <Box display="grid" marginTop="16px">
            <TableContainer sx={{ borderBottom: "none" }}>
              <Table>
                <TableHead>
                  <GroupHeadRow
                    orderBy={orderBy}
                    order={order}
                    onSortClick={onSortClick}
                  />
                </TableHead>
                <TableBody>
                  {visibleRows.map((group) => (
                    <GroupBodyRow
                      key={group.id}
                      group={group}
                      hasPermissionToChangeAllowedGroups={
                        hasPermissionToChangeAllowedGroups
                      }
                      onAddSubGroupClick={onAddSubGroupClick}
                      onEditClick={onEditClick}
                      onDeleteClick={onDeleteClick}
                      onAllowedGroupsClick={onAllowedGroupsClick}
                    />
                  ))}
                </TableBody>
                {/* <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      count={subGroups?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  </TableRow>
                </TableFooter> */}
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  )
}
