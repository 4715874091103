import {
  TableCell,
  IconButton,
  Skeleton,
  Typography,
  Box,
  Collapse,
  ClickAwayListener,
  Tooltip,
} from "@mui/material"
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"
import dayjs from "dayjs"
import { colors, VEHICLE_TYPE_OPTIONS } from "../../../../../../../../utils"
import { CollapsibleRow, CustomRow, StyledLoadingButton } from "./styled"
import { useTranslation } from "react-i18next"
import React, { useMemo } from "react"
import { VehicleEditDetails } from "../../../../../VehicleEditDetails"
import { UnfoldLessOutlined, UnfoldMoreOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { createClaimAPI } from "../../../../../../../../services"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppContext } from "../../../../../../../../contexts"
import { useNavigate } from "react-router-dom"

interface IProps {
  isLoading: boolean
  row: IVehicle
  isDisabled?: boolean
  isExpanded: boolean
  onRowToggle: (id: string) => void
  collapseRow: () => void
  onDeleteClick?: (vehicle: IVehicle) => void
}

export const VehicleTableRow = (props: IProps) => {
  const {
    isLoading,
    row,
    isDisabled,
    isExpanded,
    collapseRow,
    onDeleteClick,
    onRowToggle,
  } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { state: appState } = useAppContext()
  const navigate = useNavigate()

  const vehicleType = useMemo(() => {
    if (row.vehicleInformation.vehicleTypeSelection === "Andere") {
      return row.vehicleInformation.vehicleType
    }

    const type = VEHICLE_TYPE_OPTIONS.find(
      (o) => o.value === row.vehicleInformation.vehicleTypeSelection,
    )

    if (type) {
      return t(type.label)
    }

    return null
  }, [row])

  const { mutate: createClaim, isPending: isClaimCreating } = useMutation({
    mutationFn: () =>
      createClaimAPI({
        body: {},
        params: { groupId: appState?.groupId!, vehicleId: row.id },
      }),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)
      navigate(`/claims/${response.id}`, {
        state: { isEditModeEnabled: true, shouldSetDefaultValues: true },
      })
    },
  })

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isExpanded) {
          onRowToggle(row.id)
        }
      }}
    >
      <React.Fragment>
        <CustomRow
          open={isExpanded}
          disabled={isDisabled}
          className={isDisabled ? "disabled-effect" : ""}
          onClick={() => {
            if (isDisabled) {
              collapseRow()
            } else {
              onRowToggle(row.id)
            }
          }}
        >
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.vehicleInformation.officialRegistrationNumber?.trim() ||
                      "-"}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.vehicleInformation.chassisNumber?.trim() || "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.vehicleInformation.manufacturer?.trim() || "-"}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.vehicleInformation.manufacturerType?.trim() || "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <>
                    <DirectionsCarFilledOutlinedIcon fontSize="small" />
                    <Typography
                      variant="small"
                      className="line-clamp-1"
                      color={colors.gray9}
                      marginLeft="4px"
                    >
                      {vehicleType?.trim() || "-"}
                    </Typography>
                  </>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.vehicleInformation.initialRegistration?.trim()
                      ? dayjs(
                          row.vehicleInformation.initialRegistration,
                        ).format("DD.MM.YYYY")
                      : "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row?.insuranceInformation?.insurer?.trim() || "-"}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.group?.trim() || "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.createdAt?.trim()
                      ? dayjs(row.createdAt).format("DD.MM.YYYY")
                      : "-"}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.createdBy?.trim() || "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell className="word-break-all">
            <Box
              display="flex"
              flexDirection="column"
              paddingX="8px"
              paddingY="6px"
            >
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.lastUpdatedAt?.trim()
                      ? dayjs(row.lastUpdatedAt).format("DD.MM.YYYY")
                      : "-"}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" marginTop="4px">
                {isLoading ? (
                  <Box
                    marginLeft="4px"
                    flex={1}
                    height="24px"
                    display="flex"
                    alignItems="center"
                  >
                    <Skeleton width="70%" />
                  </Box>
                ) : (
                  <Typography
                    variant="small"
                    className="line-clamp-1"
                    color={colors.gray9}
                    marginLeft="4px"
                  >
                    {row.lastUpdatedBy?.trim() || "-"}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>

          <TableCell align="right">
            <Box display="flex" alignItems="center" gap="8px" paddingX="8px">
              <Tooltip
                title={
                  row.isVehicleFromSubGroup ? (
                    <Typography color={`${colors.white}`}>
                      {t("subGroupDisableText", {
                        groupName: row.group,
                      })}
                    </Typography>
                  ) : (
                    ""
                  )
                }
                placement="left"
                disableHoverListener={!row.isVehicleFromSubGroup}
              >
                <Box>
                  <StyledLoadingButton
                    variant="text"
                    disabled={row.isVehicleFromSubGroup}
                    onClick={(e) => {
                      e.stopPropagation()
                      createClaim()
                    }}
                    loading={isClaimCreating}
                  >
                    <Typography
                      color={
                        row.isVehicleFromSubGroup || isClaimCreating
                          ? `${colors.gray10}`
                          : `${colors.primary}`
                      }
                      variant="small"
                    >
                      {t("reportDamage")}
                    </Typography>
                  </StyledLoadingButton>
                </Box>
              </Tooltip>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onRowToggle(row.id)
                }}
              >
                {isExpanded ? (
                  <UnfoldLessOutlined color="primary" fontSize="small" />
                ) : (
                  <UnfoldMoreOutlined color="primary" fontSize="small" />
                )}
              </IconButton>
            </Box>
          </TableCell>
        </CustomRow>
        <CollapsibleRow open={isExpanded}>
          <TableCell colSpan={9}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <VehicleEditDetails
                selectedVehicle={row}
                onClose={() => onRowToggle(row.id)}
                onDeleteClick={onDeleteClick}
              />
            </Collapse>
          </TableCell>
        </CollapsibleRow>
      </React.Fragment>
    </ClickAwayListener>
  )
}
