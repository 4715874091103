import { useCallback } from "react"
import { Controller, type UseFormReturn } from "react-hook-form"
import { TextField, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { isValidPhoneNumber } from "react-phone-number-input"
import { useQuery } from "@tanstack/react-query"
import { getSalutationsAPI } from "../../../../../../services"
import {
  AutocompleteController,
  PhoneNumberInput,
} from "../../../../../../components"
import { PlaceController } from "../../../../../ClaimPage/components/FormField/components"

interface IProps {
  methods: UseFormReturn<any, any, undefined>
}

export const VehicleHolderTab = (props: IProps) => {
  const { methods } = props
  const { t } = useTranslation()
  const { control, watch } = methods

  const salutation = watch("holder.salutation")

  const validatePhoneNumber = useCallback(
    (value: string | undefined) => {
      if (!value) {
        return true
      }
      if (!isValidPhoneNumber(value)) {
        return t("invalidPhoneNumberErrorMessage")
      }
      return true
    },
    [t],
  )

  const { data: salutations, isLoading: areSalutationsLoading } = useQuery({
    queryKey: ["salutations"],
    queryFn: () => getSalutationsAPI(),
    refetchOnMount: true,
  })

  return (
    <Box
      alignItems="center"
      component="form"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box display="flex" flexDirection="column" gap="16px" width="50%">
        <Box display="flex" gap="8px">
          <AutocompleteController
            formControl={control}
            controlName="holder.salutation"
            label={t("salutation")}
            options={salutations}
            areOptionsLoading={areSalutationsLoading}
          />
        </Box>

        {salutation === "Firma" ? (
          <>
            <Box display="flex" gap="8px">
              <Controller
                name="holder.company"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("Company")}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="holder.companyPreciseRepresentationRelationship"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("companyPreciseRepresentationRelationship")}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" gap="8px">
              <Controller
                name="holder.title"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label={t("title")} fullWidth />
                )}
              />

              <Controller
                name="holder.firstName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("firstName")}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="holder.lastName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t("lastName")}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </>
        )}

        <Box display="flex" gap="8px">
          <Controller
            name="holder.address.street"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("street")}
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <PlaceController
          formControl={control}
          field={
            {
              controlName: "holder.address.postCodePlace",
              label: t("postCode"),
            } as any
          }
          disabled={false}
        />

        <Controller
          name="holder.phoneNumber"
          control={control}
          rules={{
            validate: validatePhoneNumber,
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <PhoneNumberInput
              fullWidth
              value={value}
              onChange={onChange}
              label={t("phoneNumber")}
              error={!!error?.message}
              helperText={error?.message}
            />
          )}
        />

        <Box display="flex" gap="8px" alignItems="flex-start">
          <Controller
            name="holder.email"
            control={control}
            rules={{
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: t("emailNotValid"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("email")}
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="holder.kdNo"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("customerNumber")} fullWidth />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}
