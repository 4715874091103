import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  Box,
  CircularProgress,
  Typography,
  // useMediaQuery,
  // useTheme,
} from "@mui/material"
// import { LoadingButton } from "@mui/lab"
import cloneDeep from "lodash.clonedeep"
import isEqual from "lodash.isequal"
import { type AxiosError } from "axios"
import {
  AUTHORIZED_CONTENT_MAX_WIDTH,
  AUTOSAVE_CLAIM_FORM_INTERVAL,
  CLAIM_FORM_EDITING_INACTIVITY_THRESHOLD,
  checkAllConditionsVisibility,
  colors,
  // createFormObject,
  filterObjectByFalsy,
  getNestedProperty,
} from "../../utils"
import {
  deleteDocumentAPI,
  extendLockTimeAPI,
  getClaimAPI,
  getClaimTemplateAPI,
  getCreateUpdateVehicleAPI,
  syncAdwovareAPI,
  updateClaimAPI,
  uploadClaimDocumentsAPI,
} from "../../services"
import { useAppContext, useInactivity, useToast } from "../../contexts"
// import { Print } from "@mui/icons-material"
import { AlertDialog } from "../../components"
// import { useReactToPrint } from "react-to-print"
// import { getDamagedParty } from "./utils/functions"
import { FormFooter, FormProgress, Header } from "./components"
import { SectionsList } from "./components/SectionsList/SectionsList"
import { SubmittingOverlay } from "./styled"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"

export const ClaimPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { state } = useLocation()
  // const { breakpoints } = useTheme()
  // const isGreaterThanLG = useMediaQuery(breakpoints.up("lg"))
  // const isGreaterThanXL = useMediaQuery(breakpoints.up(1350))
  const toast = useToast()
  const navigate = useNavigate()
  const { registerSessionExpirationCallback } = useInactivity()
  const { state: appState } = useAppContext()
  const queryClient = useQueryClient()

  const isClaimDeleting = !!useIsMutating({ mutationKey: ["delete-claim"] })

  const [subsectionsAdded, setSubsectionsAdded] = useState<string[]>([])

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const [isEditMode, setEditMode] = useState(false)
  const [updatingBeforeSubmit, setUpdatingBeforeSubmit] = useState(false)

  const [changedFields, setChangedFields] = useState<IFormChange[]>([])

  const [lastActiveSection, setLastActiveSection] = useState<
    string | undefined
  >()

  // const [activeStepIndex, setActiveStepIndex] = useState(0)

  const [isDuplicateAlertDialogVisible, setDuplicateAlertDialogVisible] =
    useState(false)

  const [isVehicleUpdateDialogVisible, setVehicleUpdateDialogVisible] =
    useState<boolean>(false)
  const [isVehicleCreationDialogVisible, setVehicleCreationDialogVisible] =
    useState<boolean>(false)
  const [vehicleId, setVehicleId] = useState<string | null>(null)

  const [
    isUnsubmittedChangesPromptAlertVisible,
    setUnsubmitteedChangesPromptAlertVisible,
  ] = useState(false)

  const [selectedSection, setSelectedSection] = useState<
    IClaimTemplateSection | undefined
  >()

  const [selectedSubsections, setSelectedSubsections] = useState<string[]>([])

  const [isSubmitCaseModalVisible, setSubmitCaseModalVisible] = useState(false)

  const methods = useForm({
    mode: "onTouched",
  })

  const { mutate: extendLockTime } = useMutation({
    mutationFn: () =>
      extendLockTimeAPI({ claimId: id!, groupId: appState?.groupId! }),
  })

  const { mutateAsync: updateClaim, isPending: isClaimUpdating } = useMutation({
    mutationFn: (requestParams: IUpdateClaimRequestParams) =>
      updateClaimAPI(requestParams),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)
    },
  })

  const { mutate: lockClaim, isPending: isClaimLocking } = useMutation({
    mutationFn: (lock: boolean) =>
      getClaimAPI({
        id: id!,
        params: { lockClaim: lock, groupId: appState?.groupId! },
      }),
    onSuccess: (response) => {
      queryClient.setQueryData(["claim-details", response.id], response)

      if (response.isLocked && response.isLockedBy !== user?.email) {
        setEditMode(false)
      }
    },
    onError: () => {
      setEditMode(false)
      queryClient.setQueryData(["claim-details", id], {
        ...claim,
        isLocked: true,
      })
    },
  })

  const { mutateAsync: autoSaveClaim, isPending: isClaimAutoSaving } =
    useMutation({
      mutationFn: (requestParams: IUpdateClaimRequestParams) =>
        updateClaimAPI(requestParams),
    })

  const {
    mutateAsync: syncAdwovare,
    isPending: isAdwovareSyncing,
    error: syncAdwovareError,
  } = useMutation({
    mutationFn: (force: boolean) =>
      syncAdwovareAPI({ id: id!, params: { isDuplicatePassed: !!force } }),
    onError: (error: AxiosError) => {
      if (error?.response?.status === 409) {
        setDuplicateAlertDialogVisible(true)
      }
    },
    onSuccess: (response) => {
      setVehicleId(response.vehicleId)
      toast.show(t("caseSubmittedToAdvoware"), "success")
      if (
        user?.companyType !== "CarPool" &&
        user?.companyType !== "LegalOffice"
      ) {
        navigate("/")
        return
      }

      if (response.vehicleExists && !response.hasChanges) {
        navigate("/")
      } else if (response.vehicleExists && response.hasChanges) {
        setVehicleUpdateDialogVisible(true)
      } else if (!response.vehicleExists) {
        setVehicleCreationDialogVisible(true)
      }
    },
  })

  const {
    data: claimTemplate,
    isLoading: isClaimTemplateLoading,
    isRefetching: isClaimTemplateRefetching,
    isSuccess: isClaimTemplateLoadedSuccessfully,
    isError: getClaimTemplateFailed,
  } = useQuery<IClaimTemplate>({
    queryKey: ["claim-template", appState?.groupId],
    queryFn: () => getClaimTemplateAPI({ groupId: appState?.groupId! }),
  })

  const {
    data: claim,
    isLoading: isClaimDetailsLoading,
    isRefetching: isClaimDetailsRefetching,
    isError: getClaimDetailsFailed,
    isSuccess: isClaimDetailsLoadedSuccessfully,
  } = useQuery({
    queryKey: ["claim-details", id],
    queryFn: () =>
      getClaimAPI({ id: id!, params: { groupId: appState?.groupId! } }),
    refetchOnMount: "always",
  })

  const { mutate: mutateUploadDocument, isPending: uploadingDocument } =
    useMutation({
      mutationFn: (
        requestParams: IClaimDocumentUploadRequestParams & {
          subsection?: IClaimTemplateSection
          section?: IClaimTemplateSection
        },
      ) => uploadClaimDocumentsAPI(requestParams),
      onSuccess: (response, { subsection, section }) => {
        setClaimFiles(response)

        checkCompletedState(subsection, section, response)
      },
    })

  const { mutate: mutateDeleteDocument } = useMutation({
    mutationFn: (
      requestParams: {
        body: IDeleteDocumentBody
        documentType: string
      } & {
        subsection?: IClaimTemplateSection
        section?: IClaimTemplateSection
      },
    ) => deleteDocumentAPI(requestParams.body),
    onSuccess: (_, variables) => {
      const nextFiles = claimFiles?.map((item) => {
        if (item.documentType === variables.documentType) {
          return {
            ...item,
            files: item.files.filter(
              (file) => file.id !== variables.body.documentId,
            ),
          }
        }
        return item
      })

      setClaimFiles(nextFiles)

      checkCompletedState(variables.subsection, variables.section, nextFiles)
    },
  })

  const isClaimFormLoaded = useMemo(
    () =>
      !isClaimDetailsLoading &&
      !isClaimDetailsRefetching &&
      isClaimDetailsLoadedSuccessfully &&
      !!claim &&
      !isClaimTemplateLoading &&
      !isClaimTemplateRefetching &&
      isClaimTemplateLoadedSuccessfully &&
      !!claimTemplate,
    [
      isClaimDetailsLoading,
      isClaimDetailsRefetching,
      isClaimDetailsLoadedSuccessfully,
      claim,
      isClaimTemplateLoading,
      isClaimTemplateRefetching,
      isClaimTemplateLoadedSuccessfully,
      claimTemplate,
    ],
  )

  const claimTemplateFilteredByPermissions = useMemo(() => {
    if (claim && claimTemplate) {
      // const hasPermissionToViewAllSections = claim.permissions?.some(
      //   (p) =>
      //     p.action === "NONE" &&
      //     p.claimSectionType === "None" &&
      //     p.access !== "None",
      // )

      return {
        ...claimTemplate,
        steps: claimTemplate?.steps?.filter(
          (s) =>
            claim.permissions?.some(
              (p) => p.stepType === s.stepType && p.access !== "None",
            ) || !claim.permissions.some((p) => p.stepType === s.stepType),
        ),
        // ?.map((step) => {
        //   return {
        //     ...step,
        //     sections: hasPermissionToViewAllSections
        //       ? step.sections
        //       : step?.sections?.filter(
        //           (section) =>
        //             claim.permissions?.some(
        //               (p) =>
        //                 p.action === "NONE" &&
        //                 p.claimSectionType === section.sectionType &&
        //                 p.access !== "None",
        //             ),
        //         ),
        //   }
        // }),
      }
    }
  }, [claim, claimTemplate])

  const claimDetails = useMemo(() => {
    return claim?.claimDetails
  }, [claim])

  const templateSections = useMemo(
    () =>
      claimTemplateFilteredByPermissions?.steps?.map((s) => s.sections)?.flat(),
    [claimTemplateFilteredByPermissions],
  )

  const templateSubsections = useMemo(
    () => templateSections?.map((s) => s.subSections)?.flat(),
    [templateSections],
  )

  const templateMetadatas = useMemo(
    () => templateSubsections?.map((s) => s.metadatas)?.flat(),
    [templateSubsections],
  )

  const findFirstErrorField: any = (errorObject: any) => {
    for (const key in errorObject) {
      if (errorObject?.[key]?.ref?.name && errorObject?.[key]?.type) {
        return errorObject[key].ref.name
      }

      if (typeof errorObject[key] === "object") {
        const result = findFirstErrorField(errorObject[key])
        if (result) {
          return result
        }
      }
    }
  }

  const scrollToError = useCallback(() => {
    const targetErrorElement = findFirstErrorField(methods.formState.errors)

    if (targetErrorElement) {
      const subsectionError = templateSubsections?.find(
        (ss) =>
          ss?.metadatas?.find((m) => m.controlName === targetErrorElement),
      )

      if (subsectionError) {
        if (subsectionsAdded.includes(subsectionError?.id)) {
          const targetDiv = document.getElementById(targetErrorElement)

          if (targetDiv) {
            templateSections?.forEach((s) => {
              s?.subSections?.forEach((ss) => {
                const hasTheMetadata = ss?.metadatas?.some(
                  (m) => m.controlName === targetErrorElement,
                )

                if (hasTheMetadata) {
                  if (!selectedSubsections?.includes(ss?.id)) {
                    setSelectedSubsections((prev) => [...prev, ss?.id])
                  }

                  if (selectedSection?.id !== s.id) {
                    setSelectedSection(s)
                  }
                }

                setTimeout(() => {
                  targetDiv.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }, 1200)
              })
            })
          }
        } else {
          const targetSubsection = document.getElementById(subsectionError.id)

          if (targetSubsection) {
            const parentSection = templateSections?.find(
              (s) =>
                !!s.subSections?.some((ss) => ss.id === subsectionError.id),
            )

            if (selectedSection?.id !== parentSection?.id) {
              setSelectedSection(parentSection)
            }

            setTimeout(() => {
              targetSubsection.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }, 1000)
          }
        }
      }
    }
  }, [
    methods.formState.errors,
    templateSections,
    templateSubsections,
    selectedSubsections,
    selectedSection,
    subsectionsAdded,
  ])

  // const checkMissingRequiredSubsection = useCallback(() => {
  //   templateSubsections?.filter((s) => s.metadatas.some((m) => m. ))
  // }, [templateSubsections])

  useEffect(() => {
    if (methods.formState.errors) {
      scrollToError()
    }
  }, [methods.formState.submitCount])

  const isDisabled =
    !!claim?.isLocked ||
    !isEditMode ||
    isClaimUpdating ||
    isClaimLocking ||
    isAdwovareSyncing ||
    isClaimDeleting ||
    updatingBeforeSubmit

  const initialSubsections = useMemo(() => {
    if (templateSubsections && claim) {
      return (
        templateSubsections
          ?.filter((s) =>
            !s?.isDocumentSection
              ? !!s?.metadatas?.some(
                  (m) =>
                    !!getNestedProperty(claim?.claimDetails, m.controlName),
                )
              : claim?.claimDocuments?.some(
                  (d) => d.documentType === s.sectionType && !!d.files?.length,
                ),
          )
          ?.map((s) => s.id) ?? []
      )
    }

    return undefined
  }, [templateSubsections, claim])

  useEffect(() => {
    if (initialSubsections) {
      setSubsectionsAdded(initialSubsections)
    }
  }, [initialSubsections])

  const isDirtyAlt =
    !!Object.keys(methods.formState.dirtyFields)?.length ||
    !isEqual(initialSubsections ?? [], subsectionsAdded ?? [])

  const hasSuperPermissionToEdit = useMemo(() => {
    return !!claim?.permissions?.some(
      (p) =>
        p.action === "NONE" &&
        !p.propertyControlName &&
        p.claimSectionType === "None" &&
        p.stepType === "None" &&
        (p.access === "Write" || p.access === "Delete"),
    )
  }, [claim])

  const noPermissionFields = useMemo(
    () =>
      claim?.permissions
        ?.filter(
          (p) =>
            !!p.propertyControlName &&
            (p.access === "None" || p.access === "Read"),
        )
        ?.map((p) => p.propertyControlName),
    [claim],
  )

  const permissionFields = useMemo(
    () =>
      claim?.permissions
        ?.filter(
          (p) =>
            !!p.propertyControlName &&
            (p.access === "Write" || p.access === "Delete"),
        )
        ?.map((p) => p.propertyControlName),
    [claim],
  )

  const hasPermissionToEdit = useMemo(() => {
    return (
      hasSuperPermissionToEdit ||
      !!claim?.permissions?.some(
        (p) =>
          (!!p.propertyControlName || p.claimSectionType !== "None") &&
          (p.access === "Write" || p.access === "Delete"),
      )
    )
  }, [hasSuperPermissionToEdit, claim])

  useEffect(() => {
    if (hasPermissionToEdit && state?.isEditModeEnabled) {
      setEditMode(true)
      lockClaim(true)
    }
  }, [id, hasPermissionToEdit, state?.isEditModeEnabled])

  useEffect(() => {
    if (
      state?.jumpToSubsection &&
      isClaimFormLoaded &&
      claimTemplateFilteredByPermissions
    ) {
      // const nextActiveStep =
      //   claimTemplateFilteredByPermissions.steps?.findIndex(
      //     (s) => s.stepType === state.jumpToStep,
      //   ) ?? -1

      // if (nextActiveStep !== -1) {
      //   setActiveStepIndex(nextActiveStep)

      //   setTimeout(() => {
      //     const targetDiv = document.getElementById(state.jumpToStep)

      //     targetDiv?.scrollIntoView({
      //       behavior: "smooth",
      //       block: "start",
      //     })

      //     navigate(location.pathname, { ...state, jumpToStep: undefined })
      //   }, 250)
      // }

      const activeSection =
        claimTemplateFilteredByPermissions?.steps?.[0]?.sections?.find(
          (s) =>
            !!s?.subSections?.some(
              (ss) => ss.sectionType === state.jumpToSubsection,
            ),
        )

      if (activeSection) {
        setSelectedSection(activeSection)

        const activeSubsectionId =
          activeSection?.subSections?.find(
            (ss) => ss.sectionType === state.jumpToSubsection,
          )?.id ?? "-1"

        setSelectedSubsections([activeSubsectionId])

        setTimeout(() => {
          const targetDiv = document.getElementById(activeSubsectionId)

          targetDiv?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })

          navigate(location.pathname, { ...state, jumpToSubsection: undefined })
        }, 1500)
      }
    }
  }, [
    id,
    isClaimFormLoaded,
    claimTemplateFilteredByPermissions,
    state?.jumpToSubsection,
  ])

  const saveClaim = async (autoSave = false) => {
    try {
      templateMetadatas
        ?.filter((m) => !!m?.conditions?.length)
        ?.forEach((m) => {
          const metadataValue = methods.getValues(m.controlName)

          if (!metadataValue) {
            return
          }

          const isVisible = checkAllConditionsVisibility(
            m.conditions,
            methods.getValues,
          )

          if (isVisible) {
            return
          }

          const otherMetadataExists = templateMetadatas?.some((metadata) => {
            if (
              metadata &&
              m &&
              metadata.controlName === m.controlName &&
              metadata.id !== m.id
            ) {
              const isOtherFieldVisible = checkAllConditionsVisibility(
                metadata.conditions,
                methods.getValues,
              )

              return isOtherFieldVisible
            }

            return false
          })

          if (otherMetadataExists) {
            return
          }

          methods.setValue(m.controlName, null)
        })

      templateMetadatas
        ?.filter(
          (m) =>
            m?.fieldType === "Checkbox" &&
            m.selectionOptions?.options?.some((o) => !!o.conditionField),
        )
        ?.forEach((m) => {
          const metadataValue = methods.getValues(m.controlName)

          if (!metadataValue) {
            return
          }

          const shouldResetValue = m.selectionOptions.options
            .filter((o) => !!o.conditionField)
            .some((o) => {
              const fieldValue = methods.getValues(o.conditionField)

              return (
                metadataValue === o.value && o.conditionValue !== fieldValue
              )
            })

          if (!shouldResetValue) {
            return
          }

          methods.setValue(m.controlName, null)
        })

      const values = methods.getValues()

      const filteredValues = filterObjectByFalsy(values)

      const requestParams: IUpdateClaimRequestParams = {
        id: id!,
        body: filteredValues ?? {},
        params: {
          isSavingAsDraft: !autoSave,
          lastSection: lastActiveSection!,
          groupId: appState?.groupId!,
        },
      }

      if (autoSave) {
        return await autoSaveClaim(requestParams)
      } else {
        return await updateClaim(requestParams)
      }
    } catch (_) {}
  }

  const [claimFiles, setClaimFiles] = useState<IClaimDocument[]>([])

  const missingRequiredDocuments = useMemo(() => {
    return templateMetadatas
      ?.filter(
        (metadata) =>
          metadata?.fieldType === "FileUpload" && metadata?.rules?.isRequired,
      )
      ?.map((item) => item.documentType)
      ?.find(
        (docType) =>
          !claimFiles.find(
            (file) => file.documentType === docType && file.files.length > 0,
          ),
      )
  }, [claimFiles, templateMetadatas])

  // const activeStep = useMemo(() => {
  //   return claimTemplateFilteredByPermissions?.steps?.[activeStepIndex]
  // }, [claimTemplateFilteredByPermissions, activeStepIndex])

  // const missingRequiredDocumentStep = useMemo(() => {
  //   if (missingRequiredDocuments) {
  //     return claimTemplateFilteredByPermissions?.steps?.find((step) =>
  //       step.sections.find(
  //         (section) =>
  //           section?.subSections?.find(
  //             (subSection) =>
  //               subSection?.metadatas?.find(
  //                 (metadata) =>
  //                   metadata?.fieldType === "FileUpload" &&
  //                   metadata?.rules.isRequired,
  //               ),
  //           ),
  //       ),
  //     )
  //   }
  //   return null
  // }, [claimFiles, claimTemplateFilteredByPermissions, missingRequiredDocuments])

  // useEffect(() => {
  //   if (
  //     claimTemplateFilteredByPermissions &&
  //     claimDetails &&
  //     !filterObjectByFalsy(claimDetails) &&
  //     !filterObjectByFalsy(methods.getValues()) &&
  //     state?.shouldSetDefaultValues
  //   ) {
  //     const metadatasWithDefaultValue =
  //       claimTemplateFilteredByPermissions?.steps
  //         ?.map((s) => s.sections)
  //         ?.flat()
  //         ?.map((s) => s.subSections)
  //         ?.flat()
  //         ?.map((s) => s.metadatas)
  //         ?.flat()
  //         ?.filter((m) => m?.preselectedValue)
  //         ?.map((m) => ({ property: m.controlName, value: m.preselectedValue }))

  //     if (metadatasWithDefaultValue?.length) {
  //       const defaultValues = createFormObject(metadatasWithDefaultValue)
  //       methods.reset(defaultValues)
  //     }
  //   }
  // }, [claimTemplateFilteredByPermissions, state?.shouldSetDefaultValues])

  const updateBrowserTabName = useCallback(() => {
    if (
      claimDetails?.informationDamagedVehicle?.damagedVehicle
        ?.officialRegistrationNumber
    ) {
      document.title =
        claimDetails?.informationDamagedVehicle?.damagedVehicle
          ?.officialRegistrationNumber
    } else if (
      claimDetails?.informationAboutInjury?.holder?.salutation === "Firma"
    ) {
      document.title = t("company")
    } else if (claimDetails?.informationAboutInjury?.holder?.lastName) {
      document.title = claimDetails?.informationAboutInjury?.holder?.lastName
    } else {
      document.title = t("newClaim")
    }
  }, [claimDetails, t])

  // const isNextButtonDisabled = useMemo(() => {
  //   return (
  //     activeStepIndex ===
  //       (claimTemplateFilteredByPermissions?.steps?.length ?? 0) - 1 ||
  //     (claimTemplateFilteredByPermissions?.steps?.[activeStepIndex]
  //       ?.stepType === "AccidentQuestionnarie" &&
  //       !methods.formState.isValid &&
  //       claimTemplateFilteredByPermissions?.steps?.[activeStepIndex + 1]
  //         ?.stepType !== "AccidentQuestionnarie")
  //   )
  // }, [
  //   activeStepIndex,
  //   claimTemplateFilteredByPermissions,
  //   methods.formState.isValid,
  // ])

  useEffect(() => {
    if (claimDetails) {
      updateBrowserTabName()
      setChangedFields([])
    }
  }, [claimDetails])

  useEffect(() => {
    if (claimDetails && claimTemplateFilteredByPermissions) {
      methods.reset(claimDetails, { keepErrors: true })
      checkDefaultCompletedState()
    }
  }, [claimDetails, claimTemplateFilteredByPermissions])

  useEffect(() => {
    const handleBeforeUnload = (_event: BeforeUnloadEvent) => {
      if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded])

  useEffect(() => {
    const sessionExpirationCallback = () => {
      if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }

    const unregisterCallback: any = registerSessionExpirationCallback(
      sessionExpirationCallback,
    )

    return () => {
      unregisterCallback()
    }
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded, saveClaim])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
        void saveClaim(true)
      }
    }, AUTOSAVE_CLAIM_FORM_INTERVAL)
    return () => clearInterval(interval)
  }, [isDirtyAlt, isDisabled, isClaimFormLoaded, saveClaim])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isEditMode) {
        void onEditClick()
      }
    }, CLAIM_FORM_EDITING_INACTIVITY_THRESHOLD)
    return () => clearInterval(interval)
  }, [changedFields, isEditMode])

  useEffect(() => {
    const interval = setInterval(
      async () => {
        if (isEditMode) {
          extendLockTime()
        }
      },
      (user?.lockTime || 30) * 60 * 1000 - 30000,
    )
    return () => clearInterval(interval)
  }, [isEditMode])

  const onSubmit = useCallback(async () => {
    setSubmitCaseModalVisible(false)

    if (missingRequiredDocuments) {
      toast.show(t("missingRequiredDocuments"), "error")
      // if (missingRequiredDocumentStep) {
      //   setActiveStepIndex(
      //     claimTemplateFilteredByPermissions?.steps?.indexOf(
      //       missingRequiredDocumentStep,
      //     )!,
      //   )
      // }
      return
    }

    try {
      if (isDirtyAlt) {
        setUpdatingBeforeSubmit(true)
        await saveClaim()
      }
      setUpdatingBeforeSubmit(false)
      await syncAdwovare(false)
    } catch (_) {
      setUpdatingBeforeSubmit(false)
    }
  }, [
    id,
    isDirtyAlt,
    claimFiles,
    // missingRequiredDocumentStep,
    missingRequiredDocuments,
  ])

  const loadFiles = useCallback(
    (initialFiles: IClaimDocument[]) => {
      setClaimFiles(initialFiles)
    },
    [setClaimFiles],
  )

  const addFile = useCallback(
    (
      files: File[],
      documentType: string,
      subsection?: IClaimTemplateSection,
      section?: IClaimTemplateSection,
    ) => {
      const formData = new FormData()
      files?.forEach((file) => {
        formData.append("files", file)
      })

      mutateUploadDocument({
        claimId: claim?.id!,
        params: {
          documentType,
        },
        body: formData,
        subsection,
        section,
      })
    },
    [claim],
  )

  const checkUnsavedChanges = useCallback(async () => {
    if (isDirtyAlt && !isDisabled && isClaimFormLoaded) {
      try {
        const autoSavedClaim = await saveClaim(true)

        queryClient.setQueryData(["claim-details", id], autoSavedClaim)
      } catch (_) {}
    }
  }, [methods, isDirtyAlt, queryClient, id, saveClaim])

  const {
    mutate: createOrUpdateVehicle,
    isPending: isCreatingOrUpdatingVehicle,
  } = useMutation({
    mutationFn: (isUpdate: boolean) =>
      getCreateUpdateVehicleAPI({
        claimId: claim?.id,
        vehicleId: isUpdate ? vehicleId : undefined,
      }),
    onSuccess: (_, isUpdate: boolean) => {
      const message = isUpdate
        ? t("vehicleUpdatedSuccessfullyMessage")
        : t("vehicleCreatedSuccessfullyMessage")
      toast.show(message, "success")

      if (isUpdate) {
        setVehicleUpdateDialogVisible(false)
      } else {
        setVehicleCreationDialogVisible(false)
      }
      navigate("/")
    },
    onError: (_, isUpdate: boolean) => {
      const errorMessage = isUpdate
        ? t("errorUpdatingVehicle")
        : t("errorCreating")
      toast.show(errorMessage, "error")
    },
  })

  const handleCreateVehicle = () => {
    createOrUpdateVehicle(false)
  }

  const handleUpdateVehicle = () => {
    createOrUpdateVehicle(true)
  }

  const handleCancel = () => {
    setVehicleUpdateDialogVisible(false)
    setVehicleCreationDialogVisible(false)
    navigate("/")
  }

  useEffect(() => {
    void checkUnsavedChanges()
  }, [selectedSection])

  // useEffect(() => {
  //   const targetContainer = document.getElementById(
  //     isGreaterThanLG ? "claim-form" : "form-section",
  //   )

  //   if (isGreaterThanLG && targetContainer) {
  //     const scrollCallback = () => {
  //       targetContainer.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       })
  //     }

  //     setTimeout(scrollCallback, 0)
  //   } else if (targetContainer) {
  //     targetContainer.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     })
  //   }

  //   void checkUnsavedChanges()
  // }, [activeStepIndex])

  const isDirtyAltRef = useRef(isDirtyAlt)
  const isDisabledRef = useRef(isDisabled)

  useEffect(() => {
    isDirtyAltRef.current = isDirtyAlt
    isDisabledRef.current = isDisabled
  }, [isDirtyAlt, isDisabled])

  useEffect(() => {
    return () => {
      if (isDirtyAltRef.current && !isDisabledRef.current) {
        void saveClaim(true)
      }
    }
  }, [])

  const removeFile = useCallback(
    (
      documentId: string,
      documentType: string,
      subsection?: IClaimTemplateSection,
      section?: IClaimTemplateSection,
    ) => {
      if (!claim) {
        return
      }

      const body: IDeleteDocumentBody = {
        claimId: claim.id,
        documentId,
      }
      mutateDeleteDocument({ body, documentType, subsection, section })
    },
    [claim],
  )

  useEffect(() => {
    if (claim?.claimDocuments) {
      void loadFiles(claim.claimDocuments)
    }
  }, [loadFiles, claim])

  const navigateToLastSection = () => {
    if (state?.navigateToLastUsedSection) {
      if (claim?.lastActiveSection !== "None") {
        const targetDiv = document.getElementById(
          templateSections?.find(
            (section) => section.sectionType === claim?.lastActiveSection,
          )?.id ?? "/",
        )

        if (targetDiv) {
          targetDiv.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      }
    }
  }

  const [subsectionsCompleted, setSubsectionsCompleted] = useState<string[]>([])
  const [sectionsCompleted, setSectionsCompleted] = useState<string[]>([])
  const [subsectionsForValidating, setSubsectionsForValidating] = useState<
    string[]
  >([])

  const checkDefaultCompletedState = () => {
    const metadatasNotRerenderedIds = templateMetadatas
      ?.filter(
        (m) => !checkAllConditionsVisibility(m.conditions, methods.getValues),
      )
      ?.map((m) => m.id)

    const metadatasForValidating = templateMetadatas
      ?.filter((m) => {
        const isRendered = !metadatasNotRerenderedIds?.includes(m.id)

        if (!isRendered) {
          return false
        }

        const isRequired = m?.rules.isRequired

        const hasRequiredCondition = !!m?.requiredConditions?.field

        const isRequiredForValidating = m?.rules?.isRequiredForStepper

        const rule1 =
          !!m?.requiredConditions?.value &&
          methods.getValues(m.requiredConditions.field) ===
            m.requiredConditions.value

        const rule2 =
          !!m?.requiredConditions?.exceptValue &&
          methods.getValues(m.requiredConditions.field) !==
            m.requiredConditions.exceptValue

        return (
          (hasRequiredCondition && (rule1 || rule2)) ||
          (!hasRequiredCondition && isRequired) ||
          isRequiredForValidating
        )
      })
      ?.map((m) => m.id)

    const nextSubsectionsForValidating = templateSubsections?.filter(
      (ss) =>
        ss?.metadatas?.some((m) => !!metadatasForValidating?.includes(m?.id)),
    )

    const nextSubsectionsForValidatingIds = nextSubsectionsForValidating?.map(
      (ss) => ss.id,
    )

    setSubsectionsForValidating(nextSubsectionsForValidatingIds ?? [])

    const nextSubsectionsCompleted = templateSubsections
      ?.filter((ss) => {
        if (ss.isDocumentSection) {
          return !!claim?.claimDocuments?.some(
            (d) => d?.documentType === ss?.sectionType && !!d?.files?.length,
          )
        } else {
          return !ss?.metadatas
            ?.filter((m) => !metadatasNotRerenderedIds?.includes(m?.id))
            ?.some((m) => {
              if (
                nextSubsectionsForValidatingIds?.includes(ss?.id) &&
                !metadatasForValidating?.includes(m.id)
              ) {
                return false
              }

              const isRendered = !metadatasNotRerenderedIds?.includes(m.id)

              if (!isRendered) {
                return false
              }

              const hasValue = !!methods.getValues(m.controlName)

              const hasError = !!getNestedProperty(
                methods.formState.errors,
                m.controlName,
              )

              return !hasValue || hasError
            })
        }
      })
      ?.map((ss) => ss.id)

    setSubsectionsCompleted(nextSubsectionsCompleted ?? [])

    const sectionsForValidatingIds = templateSections
      ?.filter(
        (s) =>
          !!s.subSections?.some(
            (ss) => !!nextSubsectionsForValidatingIds?.includes(ss.id),
          ),
      )
      ?.map((s) => s.id)

    const nextSectionsCompleted = templateSections
      ?.filter(
        (s) =>
          !s.subSections?.some(
            (ss) =>
              !nextSubsectionsCompleted?.includes(ss.id) &&
              (nextSubsectionsForValidatingIds?.includes(ss.id) ||
                !sectionsForValidatingIds?.includes(s.id)),
          ),
      )
      ?.map((s) => s.id)

    setSectionsCompleted(nextSectionsCompleted ?? [])
  }

  const checkCompletedState = (
    subsection?: IClaimTemplateSection,
    section?: IClaimTemplateSection,
    files?: IClaimDocument[],
  ) => {
    const metadatasNotRerenderedIds = subsection?.metadatas
      ?.filter(
        (m) => !checkAllConditionsVisibility(m.conditions, methods.getValues),
      )
      ?.map((m) => m.id)

    const metadatasForValidating = subsection?.metadatas
      ?.filter((m) => {
        const isRendered = !metadatasNotRerenderedIds?.includes(m.id)

        if (!isRendered) {
          return false
        }

        const isRequired = m?.rules.isRequired

        const hasRequiredCondition = !!m?.requiredConditions?.field

        const isRequiredForValidating = m?.rules?.isRequiredForStepper

        const rule1 =
          !!m?.requiredConditions?.value &&
          methods.getValues(m.requiredConditions.field) ===
            m.requiredConditions.value

        const rule2 =
          !!m?.requiredConditions?.exceptValue &&
          methods.getValues(m.requiredConditions.field) !==
            m.requiredConditions.exceptValue

        return (
          (hasRequiredCondition && (rule1 || rule2)) ||
          (!hasRequiredCondition && isRequired) ||
          isRequiredForValidating
        )
      })
      ?.map((m) => m.id)

    const isSubsectionForValidating = !!metadatasForValidating?.length

    const isSubsectionCompleted = subsection?.isDocumentSection
      ? !!(files || claimFiles || [])?.some(
          (d) =>
            d?.documentType === subsection?.sectionType && !!d?.files?.length,
        )
      : !subsection?.metadatas?.some((m) => {
          if (
            isSubsectionForValidating &&
            !metadatasForValidating?.includes(m.id)
          ) {
            return false
          }

          const isRendered = !metadatasNotRerenderedIds?.includes(m.id)

          if (!isRendered) {
            return false
          }

          const hasValue = !!methods.getValues(m.controlName)

          const hasError = !!getNestedProperty(
            methods.formState.errors,
            m.controlName,
          )

          return !hasValue || hasError
        })

    const isSubsectionAlreadyCompleted = subsectionsCompleted?.includes(
      subsection!.id,
    )

    let nextSubsectionsCompleted = [...subsectionsCompleted]

    if (isSubsectionCompleted && !isSubsectionAlreadyCompleted) {
      nextSubsectionsCompleted = [...nextSubsectionsCompleted, subsection!.id]
    } else if (!isSubsectionCompleted && isSubsectionAlreadyCompleted) {
      nextSubsectionsCompleted = nextSubsectionsCompleted.filter(
        (ssId) => ssId !== subsection!.id,
      )
    }

    setSubsectionsCompleted(nextSubsectionsCompleted)

    const isSubsectionAlreadyForValidating = subsectionsForValidating?.includes(
      subsection!.id,
    )

    let nextSubsectionsForValidating = [...subsectionsForValidating]

    if (isSubsectionForValidating && !isSubsectionAlreadyForValidating) {
      nextSubsectionsForValidating = [
        ...nextSubsectionsCompleted,
        subsection!.id,
      ]
    } else if (!isSubsectionForValidating && isSubsectionAlreadyForValidating) {
      nextSubsectionsForValidating = nextSubsectionsCompleted.filter(
        (ssId) => ssId !== subsection!.id,
      )
    }

    setSubsectionsForValidating(nextSubsectionsForValidating)

    const sectionSubsectionsForValidating = section?.subSections
      ?.filter((ss) => nextSubsectionsForValidating?.includes(ss.id))
      ?.map((ss) => ss.id)

    const isSectionCompleted = !section?.subSections?.some(
      (ss) =>
        !nextSubsectionsCompleted?.includes(ss.id) &&
        (sectionSubsectionsForValidating?.includes(ss.id) ||
          !sectionSubsectionsForValidating?.length),
    )

    const isSectionAlreadyCompleted = !!sectionsCompleted?.includes(section!.id)

    if (isSectionCompleted && !isSectionAlreadyCompleted) {
      setSectionsCompleted((prev) => [...prev, section!.id])
    } else if (!isSectionCompleted && isSectionAlreadyCompleted) {
      setSectionsCompleted((prev) => prev.filter((sId) => sId !== section!.id))
    }
  }

  useEffect(() => {
    if (isClaimTemplateLoadedSuccessfully && isClaimDetailsLoadedSuccessfully) {
      void navigateToLastSection()
    }
  }, [isClaimTemplateLoadedSuccessfully, isClaimDetailsLoadedSuccessfully])

  const discardChanges = useCallback(() => {
    setChangedFields([])
    setSubsectionsAdded(initialSubsections ?? [])
    methods.reset(claimDetails ?? {})
    void saveClaim(true)
  }, [claim, claimDetails])

  const onDiscardChangesClick = useCallback(() => {
    discardChanges()
    setEditMode(false)
    lockClaim(false)
  }, [discardChanges])

  const onUndoChangesClick = useCallback(() => {
    if (changedFields.length === 1) {
      discardChanges()
    } else {
      const lastState = {
        ...changedFields[changedFields.length - 1],
      }

      if (lastState?.type === "field") {
        const prevValues = changedFields?.filter(
          (f) => f.controlName === lastState.controlName,
        )

        if (prevValues?.length > 1) {
          const prevValue = cloneDeep(prevValues[prevValues.length - 2])
          methods.setValue(prevValue.controlName!, prevValue.value)
          void methods.trigger(prevValue.controlName)
        } else {
          methods.resetField(lastState.controlName!)
        }
      } else {
        setSubsectionsAdded(lastState.subsectionsState ?? [])

        methods.reset(
          { ...cloneDeep(lastState.formState) },
          {
            keepDirtyValues: true,
            keepDefaultValues: true,
            keepValues: true,
            keepDirty: true,
            keepSubmitCount: true,
            keepIsValid: true,
            keepTouched: true,
            keepErrors: true,
            keepIsSubmitted: true,
          },
        )
      }

      setChangedFields((prev) => [...prev.slice(0, prev.length - 1)])
    }

    checkDefaultCompletedState()
  }, [changedFields, checkDefaultCompletedState])

  const getDefaultValue = useCallback(
    (controlName: string) => {
      const keys = controlName.split(".")
      let defaultValue = methods.formState.defaultValues

      keys?.forEach((key) => {
        defaultValue = defaultValue?.[key] ?? null
      })

      return defaultValue
    },
    [methods.formState.defaultValues],
  )

  const onFieldBlur = useCallback(
    (
      field: IChangedField,
      subsection?: IClaimTemplateSection,
      section?: IClaimTemplateSection,
    ) => {
      const defaultValue = getDefaultValue(field.controlName)

      const prevValues = changedFields.filter(
        (f) => f.controlName === field.controlName,
      )

      if (
        (prevValues.length &&
          prevValues[prevValues.length - 1].value !== field.value) ||
        (!prevValues?.length && defaultValue !== field.value)
      ) {
        setChangedFields((prev) => [...prev, { ...field, type: "field" }])
      }

      if (subsection && section) {
        checkCompletedState(subsection, section)
      }
    },
    [changedFields],
  )

  const onFieldFocus = useCallback(
    (sectionNumber: string) => {
      setLastActiveSection(sectionNumber)
    },
    [changedFields],
  )

  const onEditClick = useCallback(
    async (skipSaving?: boolean) => {
      if (isEditMode && changedFields.length > 0 && !skipSaving) {
        await saveClaim()
      }
      lockClaim(!isEditMode)
      setEditMode((prev) => !prev)
    },
    [isEditMode, changedFields],
  )

  const onForceSubmitClick = useCallback(() => {
    setDuplicateAlertDialogVisible(false)
    void syncAdwovare(true)
  }, [])

  const onCancelSubmitClick = useCallback(
    () => setDuplicateAlertDialogVisible(false),
    [],
  )

  // const onPrintClick = useReactToPrint({
  //   content: () => {
  //     const container = document.createElement("div")
  //     const claimCostsElement = document.getElementById(
  //       "AccidentQuestionnarie",
  //     )!
  //     const caseNumberInfo = document.createElement("p")
  //     caseNumberInfo.textContent = `${t("caseNumber")}: ${
  //       claim?.caseNumber ?? t("undefined")
  //     }`

  //     const newClaimDetails = claimDetails as {
  //       informationAboutInjury: {
  //         holder: {
  //           salutation: string | null
  //           company: string | null
  //           firstName: string | null
  //           lastName: string | null
  //         }
  //       }
  //     }

  //     const damagedPartyInfo = document.createElement("p")
  //     damagedPartyInfo.textContent = `${t("damagedParty")}: ${
  //       getDamagedParty(newClaimDetails?.informationAboutInjury?.holder) ??
  //       t("undefined")
  //     }`

  //     container.appendChild(caseNumberInfo)
  //     container.appendChild(damagedPartyInfo)
  //     container.appendChild(claimCostsElement?.cloneNode(true)!)
  //     return container
  //   },
  //   onPrintError: () => {
  //     toast.show(t("failedToPrint"), "error")
  //   },
  // })

  const onSectionClick = useCallback(
    (section: IClaimTemplateSection) => {
      const nextValue = section.id !== selectedSection?.id ? section : undefined
      setSelectedSection(nextValue)

      if (nextValue) {
        setTimeout(() => {
          const targetDiv = document.getElementById(section.id)

          targetDiv?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }, 1000)
      }
    },

    [selectedSection],
  )

  const onSubsectionClick = useCallback(
    (subsectionId: string) =>
      setSelectedSubsections((prev) => {
        if (prev?.includes(subsectionId)) {
          return prev?.filter((s) => s !== subsectionId)
        }
        return [...prev, subsectionId]
      }),
    [],
  )

  const onAddSubsectionClick = useCallback(
    (subsection: IClaimTemplateSection) => {
      const formValues = methods.getValues()

      setChangedFields((prev) => [
        ...prev,
        {
          type: "subsection",
          formState: { ...cloneDeep(formValues) },
          subsectionsState: subsectionsAdded,
        },
      ])

      setSubsectionsAdded((prev) => [...prev, subsection.id])

      subsection?.metadatas?.forEach((m) => {
        if (m.preselectedValue) {
          methods.setValue(m.controlName, m.preselectedValue)
        }
      })

      setSelectedSubsections((prev) => [...prev, subsection.id])
    },
    [subsectionsAdded],
  )

  const onRemoveSubsectionClick = useCallback(
    (subsection: IClaimTemplateSection) => {
      const formValues = methods.getValues()

      setChangedFields((prev) => [
        ...prev,
        {
          type: "subsection",
          formState: { ...cloneDeep(formValues) },
          subsectionsState: subsectionsAdded,
        },
      ])

      setSubsectionsAdded((prev) => prev.filter((id) => id !== subsection.id))

      setSelectedSubsections((prev) =>
        prev.filter((id) => id !== subsection.id),
      )

      subsection?.metadatas?.forEach((m) => {
        if (m?.controlName) {
          methods.setValue(m.controlName, null)
        }
      })
    },
    [subsectionsAdded],
  )

  const subsectionsWithError = useMemo(
    () =>
      templateSubsections
        ?.filter(
          (ss) =>
            !!ss?.metadatas?.some(
              (m) =>
                !!getNestedProperty(methods.formState.errors, m.controlName),
            ),
        )
        ?.map((ss) => ss.id),
    [templateSubsections, methods?.formState],
  )

  const sectionsWithError = useMemo(
    () =>
      templateSections
        ?.filter(
          (s) =>
            s.subSections?.some(
              (ss) => !!subsectionsWithError?.includes(ss.id),
            ),
        )
        ?.map((s) => s.id),
    [templateSections, subsectionsWithError],
  )

  const onSubmitClick = useCallback(async () => {
    const isValid = await methods.trigger()

    if (!isValid) {
      scrollToError()
    } else {
      setSubmitCaseModalVisible(true)
    }
  }, [methods, scrollToError])

  const onSubmitCaseCancelClick = useCallback(
    () => setSubmitCaseModalVisible(false),
    [],
  )

  const goBack = () => {
    if (state?.fromClaimsList) {
      navigate("../claims", {
        replace: true,
        state: { jumpToLastOpenedClaim: true },
      })
    } else if (window.history?.length && window.history.length > 1) {
      navigate(-1)
    } else {
      navigate("../claims", { replace: true })
    }
  }

  const onBackPress = useCallback(() => {
    if (isDirtyAlt && !!claim?.status && claim?.status !== "InCreation") {
      setUnsubmitteedChangesPromptAlertVisible(true)
    } else {
      goBack()
    }
  }, [navigate, isDirtyAlt, claim?.status, goBack])

  useEffect(() => {
    window.history.pushState({}, document.title, window.location.href)

    const handlePopState = (e: PopStateEvent) => {
      e.preventDefault()

      if (isDirtyAlt && !!claim?.status && claim?.status !== "InCreation") {
        const confirmNavigation = window.confirm(t("unsubmittedChangesPrompt"))

        if (confirmNavigation) {
          goBack()
        } else {
          window.history.pushState({}, document.title, window.location.href)
        }
      } else {
        goBack()
      }
    }

    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [t, isDirtyAlt, claim?.status])

  // const onStepClick = useCallback(
  //   (id: string) => {
  //     const index =
  //       claimTemplateFilteredByPermissions?.steps?.findIndex(
  //         (step) => step.id === id,
  //       ) ?? -1
  //     if (index !== -1) {
  //       setActiveStepIndex(index)
  //     }
  //   },
  //   [claimTemplateFilteredByPermissions],
  // )

  const onCancelLeave = useCallback(
    () => setUnsubmitteedChangesPromptAlertVisible(false),
    [],
  )

  if (
    isClaimTemplateLoading ||
    isClaimTemplateRefetching ||
    isClaimDetailsLoading ||
    isClaimDetailsRefetching
  ) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  if (getClaimTemplateFailed || getClaimDetailsFailed) {
    return (
      <Box className="flex-center">
        <Typography>{t("failedToLoad")}</Typography>
      </Box>
    )
  }

  if (isClaimTemplateLoadedSuccessfully && !templateMetadatas?.length) {
    return (
      <Box className="flex-center">
        <Typography>{t("invalidClaimTemplate")}</Typography>
      </Box>
    )
  }

  if (
    isClaimTemplateLoadedSuccessfully &&
    templateMetadatas?.length &&
    isClaimDetailsLoadedSuccessfully &&
    !!claim
  ) {
    return (
      <Box
        className="scroll"
        display="flex"
        flex={1}
        bgcolor={colors.white}
        justifyContent="center"
      >
        <AlertDialog
          isVisible={isUnsubmittedChangesPromptAlertVisible}
          message={t("unsubmittedChangesPrompt", {
            value: syncAdwovareError?.response?.data ?? "",
          })}
          confirmLabel={t("yes")}
          onConfirm={goBack}
          onCancel={onCancelLeave}
        />
        <AlertDialog
          isVisible={isDuplicateAlertDialogVisible}
          message={t("submitDuplicatedClaimAlert", {
            value: syncAdwovareError?.response?.data ?? "",
          })}
          confirmLabel={t("submitAnyway")}
          onConfirm={onForceSubmitClick}
          onCancel={onCancelSubmitClick}
        />
        <AlertDialog
          isVisible={isSubmitCaseModalVisible}
          message={
            claim?.status === "InCreation"
              ? t("submitCaseConfirmationMessage")
              : t("resubmitCaseConfirmationMessage")
          }
          confirmLabel={
            claim?.status === "InCreation" ? t("instruct") : t("update")
          }
          onConfirm={methods.handleSubmit(onSubmit)}
          onCancel={onSubmitCaseCancelClick}
          icon={<CheckCircleOutlinedIcon fontSize="large" color="primary" />}
          loading={isAdwovareSyncing || isClaimUpdating}
        />
        <AlertDialog
          isVisible={
            isVehicleUpdateDialogVisible || isVehicleCreationDialogVisible
          }
          message={
            isVehicleUpdateDialogVisible
              ? t("vehicleDataChangedAlert")
              : t("newVehicleNoticedAlert")
          }
          confirmLabel={
            isVehicleUpdateDialogVisible ? t("update") : t("create")
          }
          onConfirm={
            isVehicleUpdateDialogVisible
              ? handleUpdateVehicle
              : handleCreateVehicle
          }
          onCancel={handleCancel}
          loading={isCreatingOrUpdatingVehicle}
        />
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        >
          <FormProgress
            sectionsWithError={sectionsWithError ?? []}
            sectionsCompleted={sectionsCompleted}
            activeSection={selectedSection!}
            sections={templateSections!}
            onSectionClick={onSectionClick}
          />

          <Header
            claim={claim}
            claimTemplate={claimTemplateFilteredByPermissions!}
            isEditMode={isEditMode}
            isClaimLocking={isClaimLocking}
            isDisabled={isDisabled}
            hasPermissionToEdit={hasPermissionToEdit}
            hasChangedFields={!!changedFields?.length}
            isDirtyAlt={isDirtyAlt}
            isClaimUpdating={isClaimUpdating}
            isAdwovareSyncing={isAdwovareSyncing}
            onEditClick={onEditClick}
            onUndoChangesClick={onUndoChangesClick}
            onDiscardChangesClick={onDiscardChangesClick}
          />

          {isAdwovareSyncing || updatingBeforeSubmit ? (
            <SubmittingOverlay>
              <CircularProgress />
              <Typography>
                {isAdwovareSyncing
                  ? t("claimSyncingText")
                  : t("claimUpdatingText")}
              </Typography>
            </SubmittingOverlay>
          ) : (
            <SectionsList
              claim={claim}
              claimFiles={claimFiles}
              template={claimTemplateFilteredByPermissions!}
              templateSections={templateSections}
              templateSubsections={templateSubsections}
              templateMetadatas={templateMetadatas}
              disabled={isDisabled}
              formMethods={methods}
              claimDocuments={claimFiles}
              isEditModeEnabled={isEditMode}
              subsectionsWithError={subsectionsWithError ?? []}
              sectionsWithError={sectionsWithError ?? []}
              hasSuperPermissionToEdit={hasSuperPermissionToEdit}
              noPermissionFields={noPermissionFields}
              permissionFields={permissionFields}
              onUploadFiles={addFile}
              onDeleteFile={removeFile}
              onFieldBlur={onFieldBlur}
              onFieldFocus={onFieldFocus}
              selectedSection={selectedSection}
              onSectionClick={onSectionClick}
              selectedSubsections={selectedSubsections}
              onSubsectionClick={onSubsectionClick}
              subsectionsAdded={subsectionsAdded}
              onAddSubsectionClick={onAddSubsectionClick}
              onRemoveSubsectionClick={onRemoveSubsectionClick}
              isUploading={uploadingDocument}
              isClaimAutoSaving={isClaimAutoSaving}
              sectionsCompleted={sectionsCompleted ?? []}
              subsectionsCompleted={subsectionsCompleted ?? []}
            />
          )}

          <FormFooter
            claim={claim}
            disabled={isDisabled}
            isClaimSaving={isClaimUpdating && !updatingBeforeSubmit}
            onSubmitClick={onSubmitClick}
            onSaveAsDraftClick={() => saveClaim()}
            onBackPress={onBackPress}
          />

          {/* <ClaimStepper
            hasPermissionToEdit={hasPermissionToEdit}
            claim={claim}
            activeStep={activeStep!}
            activeStepIndex={activeStepIndex}
            template={claimTemplateFormatted}
            isEditMode={isEditMode}
            isClaimLocking={isClaimLocking}
            isSwitchDisabled={isSwitchDisabled}
            isFormValid={methods.formState.isValid}
            onStepClick={onStepClick}
            onEditClick={onEditClick}
          /> */}
          {/* <Box display="flex" flexDirection="column" flex={1} id="form-section">
            <Box
              border={`1px solid ${colors.gray4}`}
              display="flex"
              flexDirection="column"
              marginBottom="16px"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flexWrap="wrap"
                gap="16px"
                padding="16px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  paddingRight="16px"
                >
                  {!!claimTemplateFilteredByPermissions?.companyLogo && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      marginTop="8px"
                    >
                      <Box
                        component="img"
                        className="contain-left-center"
                        src={claimTemplateFilteredByPermissions.companyLogo}
                        maxHeight="48px"
                        maxWidth="90%"
                      />
                    </Box>
                  )}
                  {!claimTemplateFilteredByPermissions?.companyLogo &&
                    !!claimTemplateFilteredByPermissions?.companyName && (
                      <Typography variant="extraLargeSemiBold" marginTop="2px">
                        {claimTemplateFilteredByPermissions.companyName}
                      </Typography>
                    )}
                </Box>

                <Box
                  display="flex"
                  flexDirection={isGreaterThanXL ? "row" : "column"}
                  gap="8px"
                >
                  {isEditMode && (
                    <>
                      <LoadingButton
                        variant="outlined"
                        onClick={() => saveClaim()}
                        loading={isClaimUpdating && !updatingBeforeSubmit}
                        disabled={isDisabled}
                      >
                        {t("saveAsDraft")}
                      </LoadingButton>
                      {hasPermissionToViewSubmit && (
                        <LoadingButton
                          onClick={methods.handleSubmit(onSubmit)}
                          disabled={isDisabled || !hasPermissionToSubmit}
                        >
                          {t("submit")}
                        </LoadingButton>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                paddingX="8px"
                paddingY="4px"
                borderTop={`1px solid ${colors.gray4}`}
                gap="4px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex={1}
                  alignItems="center"
                  gap="4px"
                >
                  <Tooltip title={t("printClaimForm")}>
                    <IconButton color="primary" onClick={onPrintClick}>
                      <Print fontSize="small" htmlColor={colors.black2} />
                    </IconButton>
                  </Tooltip>
                  {hasPermissionToDelete && claim?.status === "InCreation" && (
                    <Tooltip title={t("deleteClaim")}>
                      <IconButton
                        color="error"
                        onClick={onDeleteClaimClick}
                        disabled={isDeleteDisabled}
                      >
                        <DeleteOutlineIcon />
                        {isClaimDeleting && (
                          <CircularProgress size="16px" color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasPermissionToClose && claim?.status === "Active" && (
                    <Tooltip title={t("closeCase")}>
                      <IconButton
                        color="error"
                        onClick={onCloseCaseClick}
                        disabled={isCloseDisabled}
                      >
                        <SignalCellularNoSimOutlinedIcon />
                        {isCaseClosing && (
                          <CircularProgress size="16px" color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>

                <Tooltip title={t("undoChanges")}>
                  <IconButton
                    color="primary"
                    onClick={onUndoChangesClick}
                    disabled={isDisabled || !changedFields?.length}
                  >
                    <UndoIcon fontSize="small" htmlColor={colors.black2} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("discardChanges")}>
                  <IconButton
                    color="primary"
                    onClick={onDiscardChangesClick}
                    disabled={isDisabled || !isDirtyAlt}
                  >
                    <CancelOutlinedIcon htmlColor={colors.black2} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {isAdwovareSyncing || updatingBeforeSubmit ? (
              <SubmittingOverlay>
                <CircularProgress />
                <Typography>
                  {isAdwovareSyncing
                    ? t("claimSyncingText")
                    : t("claimUpdatingText")}
                </Typography>
              </SubmittingOverlay>
            ) : (
              <Box
                border={`1px solid ${colors.gray4}`}
                padding="16px"
                display="flex"
                flexDirection="column"
                flex={1}
                className={isGreaterThanLG ? "scroll" : ""}
                bgcolor={colors.white}
                id="claim-form"
              >
                {claimTemplateFilteredByPermissions?.steps?.map((s, i) => (
                  <Box
                    key={i}
                    display={s.id === activeStep?.id ? "inline" : "none"}
                  >
                    <StepForm
                      claim={claim}
                      disabled={isDisabled}
                      step={s}
                      formMethods={methods}
                      claimDocuments={claimFiles}
                      onUploadFiles={(files: File[], documentType: string) => {
                        addFile(files, documentType)
                      }}
                      onDeleteFile={(fileId: string, documentType: string) => {
                        removeFile(fileId, documentType)
                      }}
                      onFieldBlur={onFieldBlur}
                      onFieldFocus={onFieldFocus}
                      isUploading={uploadingDocument}
                      isClaimAutoSaving={isClaimAutoSaving}
                    />
                  </Box>
                ))}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gap="8px"
                  borderTop="1px solid lightgray"
                  paddingTop="16px"
                >
                  <Button
                    variant="outlined"
                    disabled={activeStepIndex === 0}
                    onClick={() => {
                      setActiveStepIndex(activeStepIndex - 1)
                    }}
                  >
                    {t("previous")}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isNextButtonDisabled}
                    onClick={() => {
                      setActiveStepIndex(activeStepIndex + 1)
                    }}
                  >
                    {t("next")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box> */}
        </Box>
      </Box>
    )
  }

  return null
}
